.layout {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;

  &__main {
    width: 100%;
    overflow: hidden;
  }
  &__gototop {
    position: fixed;
    bottom: 25px;
    right: 50px;
    z-index: 99;
    cursor: pointer;
    border-radius: 50%;

    span {
      animation: pulseEffect 2s infinite;
      border-radius: 50%;
    }
  }

  @keyframes pulseEffect {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
}
