.news {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  padding: 100px 252px;

  background-image: url("/images/background-news.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include xxxl {
    padding: 100px 100px;
  }

  @include xl {
    padding: 100px 60px;
  }

  @include md {
    padding: 100px 16px 36px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 40px;

    h1 {
      @include text-style(600, 64px, 126%, center, $white);
      font-family: BarlowCondensed;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      @include xxxl {
        font-size: 60px;
        line-height: 80px;
      }

      @include sm {
        font-size: 50px;
        line-height: 64px;
      }
    }

    p {
      @include text-style(400, 16px, 30px, center, #e2dfed);
      letter-spacing: 0.01em;
      max-width: 600px;

      @include sm {
        font-size: 15px;
      }
    }
  }

  &__content {
    .slick-initialized .slick-slide {
      display: flex;
      flex-direction: column;
      gap: 24px;

      & > div {
        display: flex;
      }
    }

    &__item {
      cursor: pointer;
      padding: 0 10px;

      @include sm {
        padding: 0 4px;
      }

      .image {
        width: 100%;
        height: 185px;
        position: relative;

        img {
          border-radius: 8px;
        }
      }

      h4 {
        @include text-style(700, 20px, 26px, none, $white);
        cursor: pointer;

        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        &:hover {
          color: #ee9416;
          transition: 0.5s;
        }

        @include xl {
          font-size: 16px;
          line-height: 20px;
        }
      }

      a {
        @include text-style(400, 16px, 24px, none, #ee9416);
        cursor: pointer;
      }

      p {
        @include text-style(400, 12px, 16px, none, #e2dfed);
        font-style: italic;

        padding-top: 20px;
        padding-bottom: 8px;
        span {
          text-transform: uppercase;
          font-style: normal;
        }
      }
    }
  }
}

//CUSTOM DOTS
.slick-list {
  padding-bottom: 20px;
}

.slick-dots li button {
  width: 18px;
  height: 18px;
  background: #ffffff;
  opacity: 0.2;
  border-radius: 50%;

  @include md {
    width: 12px;
    height: 12px;
  }
  &::before {
    color: unset;
  }
}

.slick-dots li {
  &.slick-active {
    button {
      background: #ee9416;
      opacity: 1;
      &::before {
        color: unset;
      }
    }
  }
}

.slick-track {
  margin: 0 auto;
}
