.news-item {
  &__image {
    position: relative;
    height: 230px;
    cursor: pointer;

    border-radius: 20px;
    margin-bottom: 16px;

    img {
      border-radius: 20px;
    }
  }

  &__title {
    @include text-style(700, 24px, 32px, left, $white);
    cursor: pointer;

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    &:hover {
      color: #ee9416;
      transition: 0.3s;
    }

    @include xxxl {
      font-size: 18px;
    }

    @include lg {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;

    &__date {
      @include text-style(400, 16px, 22px, left, #a2a3a5);
    }

    &__type {
      @include text-style(400, 16px, 22px, left, #a2a3a5);
      text-transform: uppercase;

      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.5em;
        border-top: 1px solid black;
        z-index: -1;
      }

      @include lg {
        font-size: 12px;
      }
    }
  }
}
