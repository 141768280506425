.card {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -1px;

  background: #130934;

  &__header {
    width: 100%;
    height: 100%;

    padding-top: $headerHeight;

    &__content {
      width: 100%;
      height: 100%;

      background-image: url("/images/background-news-page.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      @include sm {
        padding: 0px 24px;
      }

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 70px 0 0;

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }

      p {
        @include text-style(400, 16px, 30px, center, $white);
        letter-spacing: 0.01em;
        max-width: 800px;
        padding-bottom: 70px;

        @include sm {
          font-size: 12px;
        }
      }
    }
  }

  &__content {
    padding: 84px 257px;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include xxxl {
      padding: 84px 50px;
    }

    @include xl {
      padding: 84px 16px;
    }

    @include md {
      padding: 100px 16px;
    }

    &__header {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding-bottom: 67px;

      @include xl {
        padding-right: 38px;
        padding-left: 38px;
      }
      h2 {
        @include text-style(600, 64px, 126%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        @include xxxl {
          font-size: 60px;
          line-height: 80px;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #e2dfed;
        text-align: center;

        max-width: 800px;

        @include sm {
          font-size: 12px;
        }
      }
    }

    &__item {
      width: 100%;

      @include xl {
        padding: 0 100px;
      }

      @include lg {
        padding: 0 30px;
      }

      @include md {
        padding: 0 8px;
      }

      &--card {
        background-repeat: no-repeat;
        background-position: center;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-size: contain;
        height: 600px;
        position: relative;

        @include xl {
          height: 666px;
        }

        @include lg {
          height: 630px;
        }

        @include md {
          background-size: contain;
          height: 550px;
        }

        &--note {
          width: 100%;
          position: absolute;
          top: calc(1 / 6 * 100%);
          left: 50%;
          transform: translateX(-50%);

          @include text-style(400, 12px, 14px, center, $white);
          font-style: italic;
        }

        &--price {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        h3 {
          @include text-style(700, 38px, 48px, center, $white);
          margin: 26px 0;

          @include xxl {
            font-size: 30px;
            line-height: 40px;
          }
        }

        p {
          @include text-style(600, 16px, 24px, center, $white);
          letter-spacing: 0.01em;
          padding-bottom: 8px;
          text-transform: capitalize;
        }

        .ant-btn {
          @include text-style(900, 16px, 26px, center, #191844);
          text-transform: uppercase;

          padding: 12px 24px;
          width: 171px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          margin-top: 14px;

          &:hover {
            transition: all 0.3s ease;
            box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
              0 0 20px rgba(255, 255, 255, 0.2);
            outline-color: rgba(255, 255, 255, 0);
            outline-offset: 15px;
          }

          @include md {
            width: 150px;
            height: 30px;

            margin-top: 0px;
            font-size: 14px;
          }

          @include sm {
            width: 171px;
            height: 40px;
          }
        }
      }
    }
  }

  &__success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &__message {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      p {
        color: black;
        text-align: center;
      }
    }

    &__button-wrap {
      width: 100%;
      padding: 0px 50px;

      display: flex;
      align-items: center;
      gap: 50px;

      @include sm {
        padding: unset;
        gap: 20px;
      }
    }

    &__btn {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }
  }
}
