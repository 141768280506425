.affiliate {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 10;
    flex-direction: column;
    padding: calc($headerHeight * 2) 250px;
    gap: 24px;

    @include xxxl {
      padding: calc($headerHeight * 2) 150px;
    }

    @include xxl {
      padding: calc($headerHeight * 2) 75px;
    }

    @include ipad-mini {
      padding: calc($headerHeight * 2) 48px;
    }

    @include sm {
      padding: calc($headerHeight * 2) 20px 16px 20px;
      gap: 12px;
    }

    &--header {
      @include text-style(600, 64px, 126%, center, $white);
      font-family: BarlowCondensed;
      text-transform: uppercase;

      @include lg {
        font-size: 36px;
      }

      @include sm {
        font-size: 30px;
      }
    }

    &--bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include xl {
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;

        &--search {
          width: 100%;
        }
      }

      @include sm {
        gap: unset;
      }

      &--info {
        display: flex;
        background: #191844;
        border: 1px solid #22214e;
        border-radius: 8px;

        @include xl {
          width: 100%;
        }

        @include sm {
          flex-direction: column;
        }

        &--item {
          padding: 16px 24px;

          @include xl {
            width: 100%;
          }
        }

        &--user {
          border-right: 1px solid #22214e;

          &__user {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;

            @include xl {
              justify-content: center;
            }

            &--info {
              display: flex;
              gap: 4px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              &--name {
                display: flex;
                align-items: center;
                gap: 4px;
                @include text-style(900, 16px, 100%, center, $white);
                white-space: nowrap;
              }

              &--type {
                @include text-style(900, 14px, 100%, center, $white);
              }
            }

            &--avatar {
              position: relative;

              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;

              display: flex;
              justify-content: center;

              width: 70px;
              height: 70px;

              img {
                border-radius: 50%;
                min-width: 60% !important;
                min-height: 60% !important;
              }

              .level {
                width: 62px;
                height: 30px;

                position: absolute;
                bottom: -5%;
                left: 50%;

                transform: translateX(-50%);
              }
            }
          }

          @include sm {
            border-right: unset;
            border-bottom: 1px solid #22214e;
          }
        }

        &--wallet {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 6px;
          border-right: 1px solid #22214e;

          &--count {
            @include text-style(400, 14px, 19px, left, $white);

            span {
              font-weight: 700;
            }

            @include sm {
              text-align: center;
            }
          }

          &--money {
            @include text-style(700, 20px, 27px, left, $white);

            span {
              color: #ef4949;
            }

            @include sm {
              text-align: center;
            }
          }

          @include sm {
            border-right: unset;
            border-bottom: 1px solid #22214e;
          }
        }

        &--withdraw {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;

          button {
            padding: 17.5px 24px;
            background: #22214e;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include text-style(900, 14px, 19px, center, #ee9416);
            text-transform: uppercase;
            cursor: pointer;
            border: unset;

            &:hover {
              background: #ee9416;
              color: #22214e;
            }

            &[disabled] {
              color: white;
              background: #474669;
              cursor: unset;
            }
          }
        }
      }
    }

    &--data {
      width: 100%;

      &--tabs {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &--item {
          padding: 12px 32px;
          background: #11113d;
          border-radius: 16px 16px 0px 0px;
          @include text-style(700, 16px, 110%, center, #79798a);
          text-transform: uppercase;
          cursor: pointer;

          &--active {
            background: rgba(28, 27, 73, 0.9);
          }
        }
      }

      &--expand-icon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .table-affiliate {
        .ant-table-content {
          padding: 0px 16px 16px 16px;
        }

        .ant-table {
          background: rgba(28, 27, 73, 0.9);
          border-radius: 16px;
          border-top-left-radius: 0px;
        }

        .ant-table-thead > tr > th {
          border: unset;
          border-bottom: 1px solid #22214e;
          @include text-style(700, 14px, 26px, left, $white);
          text-transform: capitalize;
          background: transparent;
        }

        .ant-table-tbody > tr.ant-table-placeholder:hover > td {
          background: unset;
        }

        .ant-empty-description {
          color: $white;
        }

        .ant-table-body {
          &::-webkit-scrollbar {
            display: none !important;
          }

          @include sm {
            max-height: calc(50vh - 100px) !important;
          }
        }

        .ant-table-cell-scrollbar:not([rowspan]) {
          display: none;
        }

        .ant-table-tbody > tr > td {
          border: unset;
          padding: 12px;
          text-align: left;

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
            overflow-x: auto;
            display: flex;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        .ant-table-tbody > tr {
          &:nth-child(even) {
            background: #28244e;
          }
        }
      }

      .empty-table-affiliate {
        .ant-table-tbody > tr > td {
          border: unset;
          padding: 12px;
          text-align: left;

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
            overflow-x: auto;
            display: table-cell !important;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  .green-text {
    @include text-style(400, 14px, 26px, right, #23dd4c);
  }

  .white-text {
    @include text-style(400, 14px, 26px, right, $white);
  }

  .red-text {
    @include text-style(700, 14px, 26px, right, #ef4949);
  }

  .bold-text {
    @include text-style(700, 14px, 26px, left, $white);
  }

  .col-ref {
    display: flex;
    justify-content: flex-end;

    &__btn {
      @include text-style(900, 10px, 12px, center, #ee9416);
      text-transform: uppercase;
      border-radius: 2px;
      border: 1px solid #ee9416;
      transition: all 0.3s;
      padding: 4px 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      max-width: 100px;
      min-width: 100px;

      &:hover {
        background-color: #ee9416;
        color: $white;
      }
    }
  }
}

.tooltip-wrapper {
  max-width: 200px;

  &__note {
    @include text-style(400, 12px, 16px, left, #474669);
    font-style: italic;
    margin-bottom: 8px;
  }

  &__link {
    @include text-style(700, 14px, 22px, left, #ee9416);
    text-decoration: underline;
    cursor: pointer;
  }
}

.withdraw-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__input {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__select {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ant-select {
      padding: 12px;
      background-color: #dfdfdf;
      border-radius: 4px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #dfdfdf;
      border: unset;
    }

    .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 24px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 100%;
    background: #dfdfdf;
    border-radius: 4px;
    padding: 16px;
    outline: none;
    border: none;
    margin: unset;
    @include text-style(700, 20px, 24px, left, #ef4949);
  }

  button {
    max-width: 140px;
    outline: unset;
    border: unset;
    padding: 10px 24px;
    background: #ee9416;
    border: 1px solid #ee9416;
    border-radius: 4px;
    cursor: pointer;

    @include text-style(900, 14px, 19px, center, $white);
    text-transform: uppercase;

    transition: all 0.3s ease;

    &:hover {
      color: #ee9416;
      background: $white;
    }

    &[disabled] {
      color: white;
      background: #474669;
      cursor: unset;
    }
  }

  p {
    @include text-style(400, 14px, 19px, left, #191844);
  }

  h2 {
    @include text-style(700, 24px, 34px, center, #191844);
    text-transform: uppercase;
  }

  .text-bold {
    @include text-style(700, 14px, 19px, left, #191844);
  }

  .text-money {
    @include text-style(700, 14px, 19px, left, #ef4949);
  }

  .text-yellow {
    @include text-style(400, 14px, 19px, left, #ee9416);
    text-decoration-line: underline;
    cursor: pointer;
  }

  .text-note-bold {
    @include text-style(700, 12px, 16px, left, #191844);
    font-style: italic;
  }

  .text-note {
    @include text-style(400, 12px, 16px, left, #191844);
    font-style: italic;
  }
}
