.feedback {
  width: 100%;
  height: 100%;
  position: relative;

  background-image: url("/images/background-galaxy.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 100px 0px;
  margin-top: -1px;

  @include md {
    padding: 100px 16px;
  }

  @include sm {
    padding: 100px 16px 36px 16px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__header {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 40px;

    h1 {
      @include text-style(600, 64px, 126%, center, $white);
      font-family: BarlowCondensed;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      @include xxxl {
        font-size: 60px;
        line-height: 80px;
      }

      @include sm {
        font-size: 50px;
        line-height: 64px;
      }
    }

    p {
      @include text-style(400, 16px, 30px, center, #e2dfed);
      letter-spacing: 0.01em;
      max-width: 600px;

      @include sm {
        font-size: 16px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    gap: 20px;
    padding: 0px 100px;

    @include xxxl {
      gap: 10px;
    }

    @include xl {
      padding: 0px 60px;
    }

    @include md {
      padding: 0px 16px;
    }

    .slick-list {
      padding-bottom: unset;
    }

    .slick-dots {
      bottom: -10%;
      text-align: center;

      .slick-active {
        width: 60px;

        button {
          width: 60px;
          border-radius: 16px;
        }
      }
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--img {
        width: 300px;
        height: 300px;
        border-radius: 50%;

        position: relative;

        transition: all 0.3s ease;

        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

        background: linear-gradient(white, white) padding-box,
          linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c)
            border-box;
        border-radius: 50em;
        border: 8px solid transparent;

        &:hover {
          .play {
            img {
              opacity: 1;
            }
          }
        }

        img {
          object-fit: cover;

          border-radius: 50%;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .play {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);

          width: 40px;
          height: 40px;

          img {
            opacity: 0.6;
            border-radius: unset;
          }
        }

        @include xl {
          width: 250px;
          height: 250px;
        }

        @include lg {
          width: 200px;
          height: 200px;
        }
      }

      p {
        @include text-style(400, 16px, 30px, center, #e2dfed);
        text-transform: capitalize;
        margin-top: 20px;
      }
    }
  }
}
