.table {
  &__wrapper {
    //CUSTOM CSS TABLE
    .ant-table-thead {
      background: rgba(0, 0, 0, 0.2);
    }

    .ant-table-tbody {
      background: rgba(0, 0, 0, 0.2);
    }

    .ant-table-tbody {
      :nth-child(2n + 2) {
        background: rgba(255, 255, 255, 0.03);
      }
    }

    .ant-table-cell {
      @include text-style(400, 14px, 22px, left, $white);

      border: none;

      background: none !important;

      span {
        background: none !important;
      }
    }

    .ant-table-content {
      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    //CUSTOM PAGINAT TABLE
    .ant-pagination-item {
      text-align: center;
      border: 1px solid #474669;
      background: transparent;
      border-radius: 5px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        @include text-style(700, 14px, 22px, center, $white);
      }

      &:hover {
        background: #ee9416;
        border: none;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-nex {
      background: none !important;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background: none !important;
      border: none !important;
      color: #474669;
    }

    .ant-pagination-disabled,
    .ant-pagination-disabled:hover {
      background: none !important;
    }

    .ant-pagination-item-active {
      background: #ee9416;
      border: none;
    }
  }
}
