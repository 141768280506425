.register {
  &__title {
    @include text-style(700, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;

    &__deposit {
      display: flex;
      gap: 12px;
    }

    &__button {
      margin-top: 8px;
    }

    &__info {
      @include text-style(400, 16px, 26px, center, $text-color);

      span {
        @include text-style(700, 16px, 26px, center, $primary);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
