.product-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  border: 1px dashed transparent;
  padding: 12px 0px;

  &__image {
    position: relative;
    height: 148px;
    width: 148px;
    cursor: pointer;

    margin-bottom: 14px;

    @include sm {
      width: 120px;
      height: 120px;
    }

    &__level {
      position: absolute;
      z-index: 7;
      top: 0;
      left: 0;
    }

    &__main {
      position: relative;
      z-index: 2;
      background: $white;
      border-radius: 6px;

      height: 148px;
      width: 100%;

      img {
        object-fit: contain;
      }

      @include sm {
        width: 120px;
        height: 120px;
      }
    }
  }

  &__name {
    @include text-style(900, 12px, 16px, center, $white);
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 10px;
    span {
      position: relative;
      height: 19px;
    }

    &__main {
      @include text-style(900, 12px, 16px, center, $white);
    }

    &__sale {
      @include text-style(900, 12px, 16px, center, #474669);
      text-decoration-line: line-through;
    }

    &__percent {
      @include text-style(900, 12px, 16px, center, #47c751);
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    background: transparent;

    width: 100%;
    max-width: 80px;
    margin: 0 auto;

    border: 2px solid #ee9416;
    border-radius: 2.90909px;
    cursor: pointer;

    @include text-style(900, 10px, 14px, center, #ee9416);
    text-transform: uppercase;

    transition: 0.3s;

    &:hover {
      background-color: #ee9416;
      color: $white;
    }

    &.btn-disable {
      pointer-events: none;
      background-color: #474669;
      border: none;
      color: $white;
    }
  }
}

.in-use {
  border-color: #ee9416;
  border-radius: 16px;
}
