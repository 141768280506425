.sidebar {
  width: 100%;

  &__profile {
    width: 100%;
    padding: 16px;

    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10.5px);
    border-radius: 16px 16px 16px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__avatar {
      position: relative;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      display: flex;
      justify-content: center;

      width: 120px;
      height: 120px;

      img {
        border-radius: 50%;
        min-width: 60% !important;
        min-height: 60% !important;
      }

      .level {
        width: 62px;
        height: 30px;

        position: absolute;
        bottom: 0%;
        left: 50%;

        transform: translateX(-50%);

        img {
          min-width: 100% !important;
          min-height: 100% !important;
        }
      }
    }

    &__name {
      @include text-style(900, 20px, 30px, center, $white);
    }

    &__accessories {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @include xl {
        flex-wrap: wrap;
      }

      &--item {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 4px;
        border: 1px solid $primary;
        display: grid;
        place-items: center;

        img {
          min-width: calc(100% - 4px) !important;
          min-height: calc(100% - 4px) !important;
        }
      }
    }

    &__line {
      margin-top: 12px;
    }

    &__info {
      width: 100%;
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          @include text-style(700, 14px, 26px, center, $white);
          padding-bottom: 8px;
        }

        div {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-bottom: 8px;
          p {
            @include text-style(900, 14px, 26px, center, $white);
          }
        }

        p {
          @include text-style(900, 14px, 19px, center, #ee9416);
        }

        @include md {
          justify-content: center;
          gap: 20px;
        }
      }
    }

    &__action {
      width: calc(100% + 32px);
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: -24px;

      .btn-pay {
        display: flex;
        align-items: center;
        justify-content: center;

        animation: glow 1s infinite linear;

        background: linear-gradient(
          90deg,
          #086807 0%,
          #3eb805 53.13%,
          #086807 100%
        );
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.97),
          inset 0px 0px 10px rgba(0, 0, 0, 0.97);
        border-radius: 0px 0px 16px 16px;

        cursor: pointer;

        width: 100%;
        height: 36px;

        p {
          @include text-style(900, 15px, 20px, center, $white);
          text-transform: uppercase;

          text-shadow: rgb(11, 70, 21) 2px 0px 0px,
            rgb(11, 70, 21) 1.75517px 0.958851px 0px,
            rgb(11, 70, 21) 1.0806px 1.68294px 0px,
            rgb(11, 70, 21) 0.141474px 1.99499px 0px,
            rgb(11, 70, 21) -0.832294px 1.81859px 0px,
            rgb(11, 70, 21) -1.60229px 1.19694px 0px,
            rgb(11, 70, 21) -1.97998px 0.28224px 0px,
            rgb(11, 70, 21) -1.87291px -0.701566px 0px,
            rgb(11, 70, 21) -1.30729px -1.5136px 0px,
            rgb(11, 70, 21) -0.421592px -1.95506px 0px,
            rgb(11, 70, 21) 0.567324px -1.91785px 0px,
            rgb(11, 70, 21) 1.41734px -1.41108px 0px,
            rgb(11, 70, 21) 1.92034px -0.558831px 0px;

          @include lg {
            font-size: 12px;
          }
        }
      }

      .btn-update {
        width: 100%;
        height: 36px;

        display: flex;
        align-items: center;
        justify-content: center;

        animation: glow 1s infinite linear;

        background: linear-gradient(
          84.02deg,
          #ef9001 6.33%,
          #ffe609 51.74%,
          #ef9001 97.15%
        );
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.97),
          inset 0px 0px 10px rgba(0, 0, 0, 0.97);
        border-radius: 0px 0px 16px 16px;
        cursor: pointer;

        p {
          @include text-style(900, 15px, 20px, center, $white);
          text-transform: uppercase;

          text-shadow: rgb(180, 92, 12) 2px 0px 0px,
            rgb(180, 92, 12) 1.75517px 0.958851px 0px,
            rgb(180, 92, 12) 1.0806px 1.68294px 0px,
            rgb(180, 92, 12) 0.141474px 1.99499px 0px,
            rgb(180, 92, 12) -0.832294px 1.81859px 0px,
            rgb(180, 92, 12) -1.60229px 1.19694px 0px,
            rgb(180, 92, 12) -1.97998px 0.28224px 0px,
            rgb(180, 92, 12) -1.87291px -0.701566px 0px,
            rgb(180, 92, 12) -1.30729px -1.5136px 0px,
            rgb(180, 92, 12) -0.421592px -1.95506px 0px,
            rgb(180, 92, 12) 0.567324px -1.91785px 0px,
            rgb(180, 92, 12) 1.41734px -1.41108px 0px,
            rgb(180, 92, 12) 1.92034px -0.558831px 0px;

          @include lg {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    cursor: pointer;
    margin-top: 32px;

    // Remove when make some item sibar

    .ant-row {
      width: 100%;
    }

    &__group {
      p {
        cursor: unset;
        @include text-style(900, 12px, 16px, left, $white);
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      &--logout {
        p {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }

    @include md {
      flex-wrap: wrap;
      flex-direction: row;
      // TO-DO
      // justify-content: center;
      gap: 8px;
      margin-bottom: 24px;
    }

    .item {
      display: flex;
      align-items: center;

      width: 100%;
      padding: 8px;

      h3 {
        @include text-style(600, 14px, 24px, left, $white);
        margin-bottom: 0;
        padding-left: 13px;

        &:hover {
          color: #ee9416;
          transition: 0.5s;
        }

        @include sm {
          font-size: 12px;
        }
      }

      &.active {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px;

        position: relative;
        h3 {
          color: #ee9416;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;

          width: 3px;
          height: 26px;
          border-radius: 8px;
          background: #ee9416;
        }
      }
    }

    :last-child {
      margin-right: auto;
    }
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
  }

  50% {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.8);
  }

  100% {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
  }
}
