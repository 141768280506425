.courses {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -1px;
  background: #0f0830;

  &__header {
    width: 100%;
    height: 100%;

    padding-top: $headerHeight;

    &__content {
      width: 100%;
      height: 100%;

      background-image: url("/images/background-news-page.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      @include sm {
        padding: 0px 24px;
      }

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 70px 0 0;

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }

      p {
        @include text-style(400, 16px, 30px, center, $white);
        letter-spacing: 0.01em;
        max-width: 800px;
        padding-bottom: 70px;

        @include sm {
          font-size: 12px;
        }
      }
    }
  }

  &__content {
    padding: 84px 257px;
    z-index: 10;
    position: relative;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include xxxl {
      padding: 84px 50px;
    }

    @include xl {
      padding: 84px 16px;
    }

    @include md {
      padding: 100px 16px;
    }

    &__item {
      gap: 50px 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      position: relative;
      z-index: 10;

      min-height: 400px;

      @include xl {
        grid-template-columns: repeat(3, 1fr);
      }

      @include lg {
        grid-template-columns: repeat(2, 1fr);
      }

      @include sm {
        grid-template-columns: 1fr;
      }
    }

    &__paginate {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      position: relative;
      margin-top: 50px;

      .paginate {
        li {
          border-radius: 5px;
          background: transparent;
        }

        .ant-pagination-item {
          text-align: center;
          border: 1px solid #474669;

          &:hover {
            background-color: $primary;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include text-style(700, 14px, 22px, center, $white);
          }
        }

        .ant-pagination-prev,
        .ant-pagination-nex {
          background: none !important;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          background: none !important;
          border: none !important;
          color: #474669;
        }

        .ant-pagination-disabled,
        .ant-pagination-disabled:hover {
          background: none !important;
        }

        .ant-pagination-item-active {
          background: $primary;
        }
      }

      .ant-select-selector {
        border-radius: 5px !important;
        background: transparent !important;
        border: 1px solid #474669 !important;
      }

      .ant-select-selection-item {
        color: $white;
      }

      .ant-select-arrow {
        color: $white;
      }
    }

    &__empty {
      margin-top: 120px;
      display: grid;
      place-items: center;
      @include text-style(700, 32px, 126%, center, $white);
      text-transform: uppercase;
    }
  }
}
