.inpuCustom {
  display: flex;
  align-items: center;

  &__label {
    @include text-style(400, 14px, 19px, left, $white);
  }

  &__input {
    height: 40px;
    outline: none;
    @include text-style(700, 16px, 19px, left, #000000);

    &:hover {
      border-color: #ee9416;
    }
  }

  &__datePicker {
    height: 40px;
    width: 100%;

    input {
      border-color: #ee9416;
      @include text-style(700, 16px, 19px, left, #000000);

      &:hover {
        border-color: #ee9416;
      }
    }
  }
}
