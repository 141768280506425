.support {
  width: 100%;
  height: 100%;

  background: #130934;
  padding: 100px 60px;
  margin-top: -1px;

  @include md {
    padding: 100px 16px;
  }
  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 67px;

    h2 {
      @include text-style(600, 64px, 126%, center, $white);
      font-family: BarlowCondensed;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      @include xxxl {
        font-size: 60px;
        line-height: 80px;
      }
    }

    p {
      @include text-style(400, 16px, 30px, center, #e2dfed);
      letter-spacing: 0.01em;
      max-width: 600px;

      @include sm {
        font-size: 12px;
      }
    }
  }

  &__content {
    padding: 0 430px;

    @include xxxl {
      padding: 0 100px;
    }

    @include xl {
      padding: 0;
    }

    .ant-collapse {
      background: #191844;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: none;
      margin-bottom: 10px;

      svg {
        fill: #ffffff;
      }

      .ant-collapse-item {
        border-bottom: none;
      }

      .ant-collapse-header-text {
        @include text-style(700, 16px, 26px, none, $white);
        letter-spacing: 1px;
        font-style: italic;

        @include md {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .ant-collapse-content {
        background: #191844;
        padding: 0 24px;
        border-top: 1px solid #474669;
        padding-top: 10px;

        @include text-style(400, 16px, 26px, none, $white);

        letter-spacing: 1px;

        @include md {
          padding: 10px 0 0 0;
          font-size: 14px;
        }

        a {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }
}
