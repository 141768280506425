.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    @include text-style(600, 12px, 16px, left, $text-color);
  }

  &__layout {
    width: 100%;
    display: flex;
    gap: 16px;
    position: relative;

    &--icon {
      position: absolute;
      top: 28%;
      left: 20px;
    }

    input {
      @include text-style(400, 16px, 20px, left, $text-color);
      width: 100%;
      border: 1px solid #dce0ea;
      background: $white;
      padding: 16px 26px;
      padding-left: 48px;
      border-radius: 4px;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $placeholder-color;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholder-color;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    &__password {
      width: 100%;
      position: relative;

      &--icon {
        &--left {
          top: 28%;
          left: 20px;
          cursor: pointer;
          position: absolute;
        }

        &--right {
          top: 28%;
          right: 5px;
          transform: translateX(-70%);
          position: absolute;
        }
      }
    }
  }
}
