.game-history {
  h3 {
    @include text-style(700, 20px, 30px, left, $white);
  }

  &__filter {
    width: 100%;
    display: flex;
    align-items: center;

    padding-bottom: 16px;
    padding-top: 24px;

    @include md {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }

    &__group {
      width: 100%;
    }

    &__title {
      @include text-style(700, 14px, 22px, left, $white);
      padding-right: 16px;
    }

    &__action {
      display: flex;
      align-items: center;
      gap: 10px;

      &--btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 16px;

        width: 61px;
        height: 34px;
        background: #474669;
        border-radius: 4px;
        border: none;

        @include text-style(900, 14px, 22px, center, $white);
        text-transform: uppercase;
      }

      &--delete {
        @include text-style(900, 14px, 22px, center, #75748f);
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          color: white;
        }
      }
    }
  }

  .ant-picker {
    background: #191844;
    border: 1px solid #474669;
    border-radius: 4px;
    height: 34px;
  }

  .ant-picker-input > input {
    @include text-style(400, 14px, 22px, left, $white);

    &::placeholder {
      color: #75748f;
    }
  }
}

.column-date {
  @include text-style(400, 14px, 22px, left, #75748f);
}

.column-time {
  @include text-style(700, 14px, 22px, left, $white);
}

.column-room {
  @include text-style(400, 14px, 22px, left, $white);
}

.column-status {
  @include text-style(700, 14px, 22px, left, #47c751);
}

.column-point {
  @include text-style(700, 14px, 22px, left, #fff510);
}

.column-details {
  @include text-style(400, 14px, 22px, left, #ee9416);
  font-style: italic;
}

.text-color-red {
  color: #ef4949;
}
