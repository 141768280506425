.product {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -1px;
  background: #130934;

  &__background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 7;

    padding: 100px 360px;

    @include xxl {
      padding: 100px 48px;
    }

    @include sm {
      padding: 100px 16px 36px 16px;
    }

    &__header {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding-bottom: 40px;

      h1 {
        @include text-style(600, 64px, 126%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        @include xxxl {
          font-size: 60px;
          line-height: 80px;
        }

        @include sm {
          font-size: 50px;
          line-height: 64px;
        }
      }

      p {
        @include text-style(400, 16px, 30px, center, #e2dfed);
        letter-spacing: 0.01em;

        @include sm {
          font-size: 15px;
        }
      }
    }

    &__product {
      display: flex;
      align-items: center;
      gap: 60px;

      @include xl {
        gap: 40px;
      }

      @include lg {
        flex-direction: column;
      }

      &--wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &--item {
        position: relative;
        width: 360px;
        height: 360px;

        cursor: pointer;

        &::before {
          content: "";
          width: 90px;
          height: 90px;
          display: block;
          position: absolute;
          transform: rotate(-45deg) translate(-110%, -23px);
          z-index: -2;
          animation: icon-in 0.5s infinite;
          animation-timing-function: cubic-bezier(0, 0, 0.4, 1.6);
        }

        @include xl {
          width: 280px;
          height: 280px;
        }

        @include lg {
          width: 360px;
          height: 360px;
        }

        @include sm {
          width: 280px;
          height: 280px;
        }

        &--content {
          p {
            font-size: 12px !important;
            line-height: 20px !important;

            span {
              font-style: unset !important;
            }
          }
        }
      }

      p {
        padding-top: 14px;

        @include text-style(900, 24px, 34px, center, $white);
        text-transform: uppercase;

        span {
          color: #ee9416;
          font-style: italic;
        }

        @include md {
          font-size: 16px;
        }
      }
    }
  }
}

@keyframes glowing {
  0% {
    background-color: white;
    opacity: 0.2;
    left: 0%;
    transition: all 500ms cubic-bezier(0.99, 0.99, 0.99, 0.98);
    z-index: 10;
  }

  100% {
    background-color: white;
    opacity: 0.2;
    z-index: 10;
    left: 100%;
    transition: all 500ms cubic-bezier(0.99, 0.99, 0.99, 0.98);
  }
}

@keyframes back-in {
  0% {
    transform: rotate(-45deg) translate(-110%, -23px);
    left: 0%;
    z-index: 10;
  }
  80% {
    transform: rotate(-45deg) translate(5%, -23px);
    z-index: 10;
  }
  100% {
    transform: rotate(-45deg) translate(0%, -23px);
    left: 100%;
    z-index: 10;
  }
}
