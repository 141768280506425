.course-home {
  width: 100%;
  height: 100%;

  background: #130934;
  padding: 100px 0px;
  margin-top: -1px;

  @include md {
    padding: 100px 16px;
  }

  @include sm {
    padding: 100px 16px 36px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 40px;

    h2 {
      @include text-style(600, 64px, 126%, center, $white);
      font-family: BarlowCondensed;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      @include xxxl {
        font-size: 60px;
        line-height: 80px;
      }

      @include sm {
        font-size: 50px;
        line-height: 64px;
      }
    }

    p {
      @include text-style(400, 16px, 30px, center, #e2dfed);
      letter-spacing: 0.01em;
      max-width: 600px;

      @include sm {
        font-size: 15px;
      }
    }
  }

  &__content {
    padding: 0 100px;

    @include xxl {
      padding: 0 48px;
    }

    @include lg {
      padding: 0 150px;
    }

    @include md {
      padding: 0 36px;
    }

    @include sm {
      padding: 0 16px;
    }

    &__item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      background: #191844;
      border-radius: 8px;

      &--title {
        @include text-style(700, 20px, 26px, center, $white);

        text-transform: capitalize;
        width: 100%;
        margin: unset;
        background: #ee9416;
        padding: 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &--info {
        padding: 20px 36px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      p {
        @include text-style(400, 16px, 26px, none, $white);
        margin-top: 5px;
      }

      &--btn {
        @include text-style(700, 16px, 20px, center, #ee9416);
        text-transform: uppercase;
        border: 1px solid #332161;
        background: transparent;

        padding: 12px 24px;
        width: 150px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-top: 24px;

        transition: 0.5s;
        cursor: pointer;

        &:hover {
          border: 1px solid;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.2);
          outline-color: rgba(255, 255, 255, 0);
          outline-offset: 15px;
          transition: 0.5;
        }
      }
    }
  }
}
