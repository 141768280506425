.intro {
  .slick-list {
    padding-bottom: unset;
  }
  .slick-slider {
    .slick-arrow {
      width: 100px;
      height: 40px;

      border: 1px solid #ee8016;
      z-index: 21;

      top: 90%;
      right: 5%;
    }
  }
  .slick-prev {
    left: unset;
    right: calc(10% + 40px) !important;
  }
  .slick-next {
  }
  .slick-dots {
    top: calc(90% - 20px);
    bottom: unset;
    left: 10px;

    text-align: left;
    left: 5%;
  }

  width: 100%;
  min-height: 100vh;

  position: relative;

  &__background {
    background-image: url("/images/bg-banner-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;

    z-index: 2;
    position: absolute;

    @include xxxl {
      background-size: cover;
    }
  }

  &__content {
    z-index: 7;
    position: relative;

    display: flex;
    align-items: inherit;
    justify-content: center;
    width: 100%;

    gap: 50px;
    padding: 150px 180px 0 250px;

    @include xxxl {
      padding: 150px 50px 0 100px;
    }
    @include xl {
      flex-direction: column;
      align-items: center;
    }
    @include md {
      padding: 60px 48px 0 48px;
    }
    @include sm {
      padding: 60px 16px 0 16px;
    }

    &__right {
      position: relative;
      max-width: 800px;

      img {
        animation: MoveUpDown 5s linear infinite;
      }

      animation: fadeInUp 1s ease-in;
    }

    &__left {
      padding-top: 30px;

      transition: 0.5s;

      animation: fadeInDown 1s ease-in;
      display: flex;
      align-items: center;
      flex-direction: column;

      &--head {
        position: relative;
        width: 400px;
        height: 400px;

        @include sm {
          width: 300px;
          height: 300px;
        }
      }

      &--desc {
        max-width: 550px;
        margin-top: 30px;
        margin-bottom: 24px;
        @include text-style(400, 16px, 26px, left, $white);

        @include md {
          font-size: 14px;
        }
      }

      &--btn {
        width: 100%;
        display: flex;
        align-items: left;

        .ant-btn {
          @include text-style(700, 16px, 20px, center, #ee9416);
          text-transform: uppercase;
          border: 1px solid #ee9416;
          background: transparent;

          padding: 12px 24px;
          width: 150px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          &:hover {
            border: 1px solid;
            box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
              0 0 20px rgba(255, 255, 255, 0.2);
            outline-color: rgba(255, 255, 255, 0);
            outline-offset: 15px;
            transition: 0.5;
          }
        }
      }
    }
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
