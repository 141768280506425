.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &__title {
    @include text-style(600, 12px, 16px, left, $text-color);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 10;
  }

  &__options {
    font-weight: bold;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @include text-style(400, 16px, 20px, left, $text-color);
    width: 100%;
    border: 1px solid #dce0ea;
    background: $white;
    padding: 16px 26px 16px 48px;
    height: 52px;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: $placeholder-color;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border: 1px solid #dce0ea;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 26px 0px 48px;
    width: 100%;
    height: 100%;
  }
}
