.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  white-space: nowrap;
  z-index: 3;
  text-transform: uppercase;
  position: relative;
  padding: 11px 33px;
  cursor: pointer;
  width: 100%;
  border: unset;
  @include text-style(700, 16px, 26px, center, $white);

  &:hover {
    box-shadow: -1px 1px 5px 3px rgba(255, 255, 255, 0.3);
  }

  &:active {
    box-shadow: -1px 1px 5px 3px rgba(255, 255, 255, 0.3);
  }
}
