.profile {
  width: 100%;
  height: 100%;

  &__content {
    h3 {
      @include text-style(700, 20px, 30px, left, $white);
      margin-bottom: 0px;
    }

    &__wrap {
      max-height: calc(100vh - 300px);
      overflow: auto;

      @include xxxl {
        max-height: calc(100vh - 200px);
      }

      @include lg {
        max-height: calc(100vh - 100px);
      }
    }

    &__item {
      margin-top: 24px;

      h3 {
        @include text-style(900, 14px, 22px, left, #ee9416);
        text-transform: uppercase;

        margin-bottom: 0px;
        padding-bottom: 8px;

        border-bottom: 1px solid #22214e;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 70px;
        padding-top: 20px;
        position: relative;

        span {
          @include text-style(400, 14px, 19px, left, $white);

          @include sm {
            font-size: 12px;
          }
        }

        :first-child {
          width: 110px;
        }

        :last-child {
          @include text-style(600, 14px, 19px, left, $white);

          @include sm {
            font-size: 12px;
          }
        }

        .btn-copy {
          position: absolute;
          right: 24px;
          padding: 2px 16px;
          width: 93px;
          height: 24px;
          background: #eb861d;
          border-radius: 4px;

          cursor: pointer;

          @include text-style(900, 12px, 20px, center, #191844);
          text-transform: uppercase;

          &:hover {
            color: #eb861d;
            background: #ffffff;
            transition: 0.5s;
          }
        }

        .link {
          @include text-style(400, 14px, 19px, left, $white);

          max-width: 350px;
          @include sm {
            font-size: 12px;
          }
        }
        @include sm {
          gap: 10px;
        }
      }

      .update {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #22214e;
        padding-bottom: 8px;

        h4 {
          @include text-style(900, 14px, 22px, left, #ee9416);
          text-transform: uppercase;

          margin-bottom: 0;
        }

        div {
          display: flex;
          align-items: center;
          gap: 6px;

          position: relative;
          cursor: pointer;
          p {
            @include text-style(700, 14px, 22px, left, $white);

            &:hover {
              color: #ee9416;
              transition: 0.5s;
            }
          }
        }
      }

      &--link {
        margin-top: 24px;

        h3 {
          @include text-style(900, 14px, 22px, left, #ee9416);
          text-transform: uppercase;

          margin-bottom: 0px;
          padding-bottom: 8px;

          border-bottom: 1px solid #22214e;
        }

        .item {
          padding-top: 20px;
          width: 100%;
          display: flex;
          gap: 70px;

          .titleLink {
            width: 90px;
            @include text-style(400, 14px, 19px, left, $white);

            @include sm {
              font-size: 12px;
            }
          }

          .test {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 160px);

            .textLink {
              @include text-style(400, 14px, 19px, left, $white);

              @include sm {
                font-size: 12px;
              }
            }

            .btn-copy {
              padding: 2px 0px;
              width: 93px;
              height: 24px;
              background: #eb861d;
              border-radius: 4px;

              cursor: pointer;

              @include text-style(900, 12px, 20px, center, #191844);
              text-transform: uppercase;

              &:hover {
                color: #eb861d;
                background: #ffffff;
                transition: 0.5s;
              }
            }

            @include lg {
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
            }
          }

          @include sm {
            gap: 10px;
          }
        }
      }
    }
  }
}
