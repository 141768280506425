.introduce-modal {
  width: auto !important;

  .ant-modal-content {
    background-color: unset;
  }

  .ant-modal-close-x {
    background: rgba(0, 0, 0, 0.53);
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #ffffff;
    }

    @include md {
      width: 20px;
      height: 20px;
    }
  }

  .ant-modal-close {
    top: 10%;
    right: 8%;

    @include md {
      right: 4%;
    }
  }

  &__wrap {
    position: relative;
    max-width: 750px;
    height: 100%;
    &__image {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      position: absolute;
      z-index: 7;
      bottom: 10%;

      &--image {
        cursor: pointer;
        transition: 0.5s;
        width: 290px;
        &:hover {
          scale: 1.1;
        }

        @include md {
          width: 200px;
        }

        @include sm {
          width: 150px;
        }

        @include xs {
          width: 100px;
        }
      }
    }
  }
}
