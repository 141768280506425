.input-search {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__layout {
    width: 100%;
    display: flex;
    gap: 16px;
    position: relative;

    &--icon {
      position: absolute;
      top: 28%;
      left: 20px;
    }

    input {
      @include text-style(400, 16px, 20px, left, $white);
      width: 100%;
      background: #191844;
      border: 1px solid #22214e;
      padding: 11px 16px;
      padding-left: 48px;
      border-radius: 8px;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $placeholder-color;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholder-color;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
