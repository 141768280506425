// variables content
$w-content: 1128px;
$headerHeight: 64px;
$headerHeightMobile: 70px;

// color
$primary: #ee9416;
$black: #121212;
$gray: #a2a3a5;
$light-gray: #f8f8f8;
$orange: #f2610f;
$red: #d72323;
$dark-red: #d12f25;
$text-color: #303841;
$placeholder-color: #868a96;
$text-gray: #606060;
$white: #fff;
$line-color: #ededed;
$border-table: #d8d8d8;

// hover
$hover-spread: 0 0 10px 2px;
