.banner-about-us {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;

  &__background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-image: url("/icons/bg-story.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include lg {
      background-size: cover;
    }
  }

  &__content {
    z-index: 7;
    position: relative;

    display: flex;
    align-items: inherit;
    justify-content: center;
    width: 100%;

    gap: 50px;
    padding: 150px 180px 0 250px;

    @include xxxl {
      padding: 150px 50px 0 100px;
    }
    @include xl {
      flex-direction: column;
      align-items: center;
    }
    @include md {
      padding: 100px 48px 0 48px;
    }
    @include sm {
      padding: 100px 16px 0 16px;
    }

    &__right {
      position: relative;
      max-width: 800px;

      animation: MoveUpDown 5s linear infinite;

      @include xl {
        order: 2;
      }
    }

    &__left {
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        @include text-style(600, 80px, 110%, left, #ee9416);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: BarlowCondensed;

        margin-bottom: 0px;

        @include xl {
          max-width: 100%;
          text-align: center;
        }

        @include md {
          font-size: 48px;
        }
      }

      div {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #faff0a 0%,
          rgba(250, 249, 2, 0) 100%
        );
        filter: blur(0.25px);
        transform: matrix(1, 0, 0.07, 1, 0, 0);
        height: 2px;
        width: 30%;

        margin-top: 20px;

        @include xl {
          width: 100%;
        }
      }

      p {
        @include text-style(700, 18px, 28px, left, $white);

        max-width: 500px;
        margin-top: 70px;

        @include xl {
          max-width: 100%;
          text-align: center;
        }

        @include lg {
          margin-top: 20px;
        }

        @include md {
          font-size: 16px;
        }
      }

      .text-yellow {
        color: #ee9416;
        margin-top: 10px;
      }

      @include xl {
        order: 1;
      }
    }
  }

  @include md {
    min-height: unset;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
