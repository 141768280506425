@import "@styles/breakpoints";

.about {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  background: #140c38;
  position: relative;

  &__background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-image: url("/images/background-abuot.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__left {
      position: relative;
      left: 5%;
      bottom: -50%;
      z-index: 72;

      height: 336px;
      width: 250px;

      @include xxxl {
        bottom: -40%;
        height: 290px;
      }
    }

    &__right {
      position: relative;
      width: 100%;
      height: 100%;

      &--icon {
        width: 15px;
        height: 15px;
        background: transparent;
        position: relative;
        animation: mymove 2s infinite;
        animation-direction: alternate;
        z-index: 72;
      }
      .icon1 {
        right: -80%;
        top: -40%;
      }

      .icon2 {
        border-radius: 50%;
        right: -70%;
        top: -35%;
        z-index: 72;
      }

      .icon3 {
        border-radius: 50%;
        right: -75%;
        top: -35%;
        z-index: 72;
      }

      .icon4 {
        right: -80%;
        top: -35%;
        z-index: 72;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 35px;

    position: relative;
    z-index: 7;
    padding: 74px 217px;

    @include xxxl {
      padding: 30px 70px;
    }

    @include lg {
      padding: 30px 45px;
    }

    @include md {
      flex-direction: column;
      padding: 50px 16px;
      gap: 0;
    }
    &__logo {
      position: relative;
      width: 640px;
      height: 618px;

      &--logo {
        position: absolute;
        width: 100%;
        height: 225px;
        bottom: 0%;
        z-index: 7;
        left: 50%;
        transform: translateX(-50%);

        @include xxxl {
          bottom: -8%;
          height: 200px;
        }

        @include xl {
          bottom: -15%;
          height: 180px;
        }

        @include lg {
          height: 160px;
          bottom: -13%;
        }

        @include ipad-mini {
          bottom: -7%;
        }

        @include md {
          bottom: -12%;
        }

        @include sm {
          height: 150px;
          bottom: -15%;
        }
      }

      &--left {
        position: absolute;
        width: 320px;
        height: 540px;
        left: 0%;
        cursor: pointer;
        z-index: 2;

        // -moz-transform: rotateY(0deg);
        // -moz-transform-origin: right;
        // transform: rotateY(0deg);
        // transform-origin: right;
        // transition: 1s all;

        // &:hover {
        //   -moz-transform: rotateY(180deg);
        //   -moz-transform-origin: right;
        //   transform: rotateY(180deg);
        //   transform-origin: right 50%;
        //   transition: 1s all;
        //   z-index: 3;
        // }

        @include xxxl {
          width: 275px;
          height: 524px;
        }

        @include xl {
          width: 225px;
          height: 390px;
        }

        @include lg {
          width: 200px;
          height: 340px;
        }

        @include ipad-mini {
          width: 170px;
        }

        @include md {
          width: 225px;
          height: 380px;
        }

        @include sm {
          height: 290px;
          width: 175px;
        }
      }

      &--right {
        position: absolute;
        width: 320px;
        height: 540px;
        right: 0%;
        cursor: pointer;
        z-index: 2;

        -moz-transform: rotateY(0deg);
        -moz-transform-origin: left;
        transform: rotateY(0deg);
        transform-origin: left;
        transition: 1s all;

        &:hover {
          -moz-transform: rotateY(-180deg);
          -moz-transform-origin: left;
          transform: rotateY(-180deg);
          transform-origin: left;
          transition: 1s all;
          z-index: 3;
        }

        @include xxxl {
          width: 275px;
          height: 524px;
        }

        @include xl {
          width: 225px;
          height: 390px;
        }

        @include lg {
          width: 200px;
          height: 340px;
        }

        @include ipad-mini {
          width: 170px;
        }

        @include md {
          width: 225px;
          height: 380px;
        }

        @include sm {
          height: 290px;
          width: 175px;
        }
      }

      &--left-sup {
        position: absolute;
        width: 320px;
        height: 540px;
        left: 0%;
        cursor: pointer;
        z-index: 1;

        @include xxxl {
          width: 275px;
          height: 524px;
        }

        @include xl {
          width: 225px;
          height: 390px;
        }

        @include lg {
          width: 200px;
          height: 340px;
        }

        @include ipad-mini {
          width: 170px;
        }

        @include md {
          width: 225px;
          height: 380px;
        }

        @include sm {
          height: 290px;
          width: 175px;
        }
      }
      &--right-sup {
        position: absolute;
        width: 320px;
        height: 540px;
        right: 0%;
        cursor: pointer;
        z-index: 1;

        @include xxxl {
          width: 275px;
          height: 524px;
        }

        @include xl {
          width: 225px;
          height: 390px;
        }

        @include lg {
          width: 200px;
          height: 340px;
        }

        @include ipad-mini {
          width: 170px;
        }

        @include md {
          width: 225px;
          height: 380px;
        }

        @include sm {
          height: 290px;
          width: 175px;
        }
      }

      @include xxxl {
        width: 550px;
        height: 524px;
      }

      @include xl {
        width: 450px;
        height: 390px;
      }

      @include lg {
        width: 400px;
        height: 350px;
      }

      @include ipad-mini {
        width: 340px;
        height: 350px;
      }

      @include md {
        height: 390px;
        width: 450px;
      }

      @include sm {
        width: 350px;
        height: 300px;
      }
    }

    &__content {
      max-width: 635px;
      z-index: 2;
      @include xxl {
        max-width: 500px;
      }
      @include xl {
        max-width: 300px;
      }
      @include md {
        max-width: unset;
      }
      &__title {
        @include text-style(600, 64px, 126%, start, $white);
        text-transform: uppercase;
        font-family: BarlowCondensed;
        letter-spacing: 0.05em;
        margin-bottom: 16px;

        @include xxxl {
          font-size: 60px;
          line-height: 80px;
        }

        @include lg {
          margin-bottom: 0;
        }
      }

      &__supTitle {
        @include text-style(700, 16px, 24px, start, #4b4b7c);
        font-family: BarlowCondensed;
        font-style: italic;
        letter-spacing: 0.91em;
        text-transform: uppercase;

        @include xxxl {
          font-size: 12px;
        }
        @include xs {
          letter-spacing: 0.5em;
        }
      }

      &__des {
        letter-spacing: 0.01em;
        @include text-style(400, 16px, 30px, start, #e2dfed);
        margin: 32px 0;

        @include xxxl {
          font-size: 14px;
        }
      }

      .ant-btn {
        @include text-style(700, 16px, 20px, center, #ee9416);
        text-transform: uppercase;
        border: 1px solid #332161;
        background: transparent;

        padding: 12px 24px;
        width: 150px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        &:hover {
          border: 1px solid;
          box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.2);
          outline-color: rgba(255, 255, 255, 0);
          outline-offset: 15px;
          transition: 0.5;
        }
      }
    }
  }
}

@keyframes mymove {
  from {
    transform: rotate(0deg);
    border: 0.896171px solid rgb(229, 29, 29);
    filter: drop-shadow(0px 0px 5.37703px rgba(255, 242, 207, 0.69))
      drop-shadow(0px 0px 15.2349px rgba(255, 246, 230, 0.33));
  }
  to {
    transform: rotate(45deg);
    border: 0.896171px solid #ffcd29;
    filter: drop-shadow(0px 0px 5.37703px rgba(255, 242, 207, 0.69))
      drop-shadow(0px 0px 15.2349px rgba(255, 246, 230, 0.33));
  }
}
