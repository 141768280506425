.card-product {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  transition: all 0.3s ease;

  height: 100%;

  &:hover {
    transform: translateY(-15px);
    cursor: pointer;
  }

  &__content {
    padding: 20px;
    background-color: #2a1e5f;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--title {
      @include text-style(700, 20px, 30px, left, $white);
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--category {
      @include text-style(400, 14px, 20px, left, $white);
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--option {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--button {
        border: unset;
        padding: 7px 23px;
        background-color: $primary;
        text-transform: uppercase;
        @include text-style(900, 16px, 26px, center, #191844);
        transition: all 0.3s ease;

        &:hover {
          cursor: pointer;

          // box-shadow: inset 0 0 20px rgb(255 255 255 / 50%),
          //   0 0 20px rgb(255 255 255 / 20%);
          // outline-color: rgba(255, 255, 255, 0);
          // outline-offset: 15px;
        }

        &[disabled] {
          background: #474669;

          &:hover {
            color: $white;
            background: #474669;
          }
        }
      }

      &--price {
        display: flex;
        gap: 8px;
        align-items: center;

        &--number {
          @include text-style(700, 24px, 34px, center, $white);
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 300px;
    background: white;
    position: relative;

    img {
      object-fit: contain;
    }
  }
}
