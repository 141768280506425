.banking-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__content {
    display: flex;
    gap: 24px;

    @include ipad-mini {
      flex-direction: column;
    }
  }

  &__rate {
    flex-basis: 50%;
    width: 100%;
    border-right: 1px solid #22214e;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px 40px;

    &--radio {
      display: flex;
      gap: 8px;
    }

    &--price {
      @include text-style(400, 14px, 19px, left, $white);
    }

    &--coin {
      @include text-style(600, 14px, 19px, left, $white);
    }

    @include ipad-mini {
      border-right: unset;
      border-bottom: 1px solid #22214e;
      padding-bottom: 24px;
    }
  }

  &__info {
    width: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--item {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &--bold {
      @include text-style(700, 14px, 19px, left, $white);
    }

    &--text {
      @include text-style(400, 14px, 19px, left, $white);
    }

    &__btn {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }
  }

  &__next {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__bank {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      @include ipad-mini {
        justify-content: space-between;
      }

      p {
        @include text-style(900, 14px, 22px, left, #ee9416);
        text-transform: uppercase;
      }

      .ant-select {
        min-width: 250px;
        background-color: #e0ddf9;
        border-radius: 4px;

        @include sm {
          min-width: 180px;
        }
      }
    }

    &__info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include sm {
        flex-direction: column;
        gap: 24px;
      }

      &--content {
        display: flex;
        flex-basis: 70%;
        width: 100%;
        flex-direction: column;

        &--transaction {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 14px;

          &--item {
            width: 100%;
            display: flex;
            gap: 24px;

            p:first-child {
              min-width: 150px;
            }
          }
        }

        &--note {
          @include text-style(400, 14px, 19px, left, #ef4949);
          font-style: italic;
          margin-top: 40px;
        }
      }

      &--qr {
        display: flex;
        flex-basis: 30%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 14px;
      }
    }
  }

  &__pending {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &__message {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }

    &__btn-wrapper {
      display: flex;
      gap: 16px;
    }
  }

  .text {
    @include text-style(400, 14px, 19px, left, $white);
  }

  .text-bold {
    @include text-style(700, 14px, 19px, left, $white);
  }

  .text-center {
    text-align: center;
  }

  .text-pending {
    @include text-style(700, 20px, 30px, center, #b3b3b3);
  }
}
