.news-page {
  width: 100%;
  min-height: 100vh;
  background: #0f0830;

  &__header {
    width: 100%;
    height: 100%;

    padding-top: $headerHeight;

    &__content {
      width: 100%;
      height: 100%;

      background-image: url("/images/background-news-page.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 70px 0;

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }

  &__container {
    padding: 84px 250px 171px 250px;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include xxxl {
      padding: 84px 200px 100px 200px;
    }

    @include xxl {
      padding: 84px 100px 100px 100px;
    }

    @include xl {
      padding: 84px 50px 70px 50px;
    }

    @include md {
      padding: 50px 36px;
    }

    @include sm {
      padding: 50px 16px;
    }

    &__content {
      padding-bottom: 50px;
    }

    &__paginate {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      position: relative;
      margin-top: 50px;

      .paginate {
        li {
          border-radius: 5px;
          background: transparent;
        }

        .ant-pagination-item {
          text-align: center;
          border: 1px solid #474669;

          &:hover {
            background-color: $primary;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include text-style(700, 14px, 22px, center, $white);
          }
        }

        .ant-pagination-prev,
        .ant-pagination-nex {
          background: none !important;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          background: none !important;
          border: none !important;
          color: #474669;
        }

        .ant-pagination-disabled,
        .ant-pagination-disabled:hover {
          background: none !important;
        }

        .ant-pagination-item-active {
          background: $primary;
        }
      }
    }
  }
}
