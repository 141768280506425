.contact-home {
  width: 100%;
  background: #0f0830;

  margin-top: -1px;
  padding: 100px 150px;

  @include xxxl {
    padding: 100px 150px;
  }

  @include xxl {
    padding: 100px;
  }

  @include xl {
    padding: 100px 70px 70px 50px;
  }

  @include md {
    padding: 100px 36px;
  }

  @include sm {
    padding: 100px 16px 36px 16px;
  }

  &__header {
    width: 100%;
    height: 100%;

    &__content {
      width: 100%;
      height: 100%;

      h1 {
        @include text-style(600, 64px, 126%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        @include xxxl {
          font-size: 60px;
          line-height: 80px;
        }

        @include sm {
          font-size: 50px;
          line-height: 64px;
        }
      }
    }
  }

  &__container {
    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__content {
      width: 100%;
      height: 100%;

      &--title {
        h4 {
          @include text-style(700, 20px, 30px, left, #ee9416);
        }

        span {
          @include text-style(400, 16px, 26px, left, $white);
          max-width: 694px;
        }

        margin-bottom: 48px;
      }

      &--form {
        .title {
          @include text-style(700, 16px, 22px, left, $white);

          span {
            color: #de1414;
          }
        }

        .input {
          height: 57px;
          outline: none;
          @include text-style(700, 16px, 19px, left, #000000);
          border-radius: 8px;
          &:hover {
            border-color: #ee9416;
          }

          margin-top: 8px;
        }

        .text-area {
          height: 196px;
          outline: none;
          @include text-style(700, 16px, 19px, left, #000000);
          border-radius: 8px;
          &:hover {
            border-color: #ee9416;
          }

          margin-top: 8px;
        }

        .ant-form-item {
          margin-bottom: 30px;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 24px;
          gap: 8px;
          width: 125px;
          height: 62px;
          background: #ee9416;
          border-radius: 4px;
          border: none;

          @include text-style(900, 14px, 19px, center, $white);
          text-transform: uppercase;
        }

        .ant-form-item-explain-error {
          color: #ee9416;
          padding-top: 5px;
        }
      }

      &--location {
        width: 100%;
        height: 100%;

        iframe {
          border-radius: 8px;
        }

        @include lg {
          height: 600px;
        }

        @include md {
          height: 400px;
        }
      }
    }
  }
}
