.access-avatar {
  h3 {
    @include text-style(700, 20px, 30px, left, $white);
  }

  &__content {
    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-total {
        @include text-style(700, 14px, 22px, center, $white);
      }

      &-group {
        display: flex;
        align-items: center;
        gap: 16px;

        .title {
          width: 100%;
          max-width: 150px;

          @include text-style(700, 14px, 22px, center, $white);
        }

        .filter-custom {
          max-width: 150px;
        }
      }
    }

    &__gift {
      margin-top: 16px;
    }

    &__paginate {
      margin-top: 16px;
      .paginate {
        li {
          border-radius: 5px;
          background: transparent;
        }

        .ant-pagination-item {
          text-align: center;
          border: 1px solid #474669;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include text-style(700, 14px, 22px, center, $white);
          }
        }

        .ant-pagination-prev,
        .ant-pagination-nex {
          background: none !important;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          background: none !important;
          border: none !important;
          color: #474669;
        }

        .ant-pagination-disabled,
        .ant-pagination-disabled:hover {
          background: none !important;
        }

        .ant-pagination-item-active {
          background: #ee9416;
        }
      }
    }
  }
}
