.accessories-item {
  width: 100%;
  display: flex;
  gap: 40px;
  align-items: center;

  @include sm {
    flex-direction: column;
    gap: 24px;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 250px;

    img {
      border-radius: 8px;
      object-fit: contain;
    }
  }

  &__content {
    width: 100%;

    h2 {
      @include text-style(700, 32px, 42px, left, #191844);
      margin-bottom: 0px;

      @include md {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .price {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        span {
          margin-top: 6px !important;
        }
      }

      .main-price {
        @include text-style(700, 20px, 27px, left, #191844);

        @include md {
          font-size: 16px;
        }
      }

      .price-at-sale {
        @include text-style(700, 20px, 27px, left, #474669);
        text-decoration-line: line-through;

        @include md {
          font-size: 16px;
        }
      }
    }

    .title {
      @include text-style(700, 14px, 24px, left, #191844);
    }

    .des {
      @include text-style(400, 14px, 24px, left, #191844);
      height: 120px;
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #eb861d;
      }

      @include sm {
        height: 100px;
      }
    }

    &__select {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;

      .ant-select {
        width: 100%;
        max-width: 400px;
        overflow: hidden;
      }

      a {
        font-weight: 700;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 12px;

      @include md {
        flex-direction: column;
        align-items: flex-start;
      }
      .confirm {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        gap: 8px;

        height: 39px;

        background: linear-gradient(90deg, #ee9416 0%, #ee7e16 100%);
        border-radius: 4px;

        @include text-style(900, 14px, 19px, center, $white);
        text-transform: uppercase;

        transition: all 0.3s ease;

        &:hover {
          color: #ee9416;
          background: white;
        }

        &[disabled] {
          background: #474669;

          &:hover {
            color: $white;
            background: #474669;
          }
        }

        @include sm {
          width: 100%;
        }
      }

      .cancel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        gap: 8px;

        height: 39px;

        background: #474669;
        border-radius: 4px;

        @include text-style(900, 14px, 19px, center, $white);
        text-transform: uppercase;

        transition: all 0.3s ease;

        &:hover {
          opacity: 0.6;
        }

        @include sm {
          width: 100%;
        }
      }
    }
  }
}

.modal-item {
  .ant-modal-content {
    border-radius: 16px;

    .ant-modal-body {
      padding: 32px;
    }
  }
}

.modal-address {
  .ant-modal-content {
    border-radius: 16px;

    .ant-modal-body {
      padding: 32px;
    }

    h3 {
      color: black;
    }

    .address__form__label {
      color: black;
    }

    .address__form__btn-wrap > button {
      border: 1px solid $primary;
    }
  }
}
