.news-details {
  width: 100%;
  min-height: 100vh;
  background: #0f0830;

  &__header {
    width: 100%;
    height: 100%;

    padding-top: $headerHeight;

    &__content {
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 136px 0;
        backdrop-filter: brightness(50%);

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }

  &__container {
    padding: 80px 250px 100px 250px;

    @include xxxl {
      padding: 80px 200px 100px 200px;
    }

    @include xl {
      padding: 40px 70px 60px 70px;
    }

    @include md {
      padding: 40px 36px 60px 36px;
    }

    @include sm {
      padding: 40px 16px 60px 16px;
    }

    &__content {
      &--title {
        h2 {
          @include text-style(700, 40px, 44px, left, $white);

          @include lg {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .info {
          display: flex;
          align-items: center;
          gap: 22px;

          span {
            @include text-style(400, 16px, 22px, left, $white);

            @include lg {
              font-size: 12px;
            }
          }

          :first-child {
            text-transform: uppercase;
            position: relative;
          }
        }
      }

      &--hidden-image {
        visibility: hidden;
      }

      &--content {
        color: $white;
        letter-spacing: 1px;
        font-family: NunitoSans;
        border-bottom: 1px solid #e3e3e3;

        img {
          max-width: 80%;
          border-radius: 3%;
          object-fit: cover;

          display: flex;
          justify-content: center;
          align-items: center;

          margin: 14px auto;
        }
      }

      &--footer {
        display: flex;
        gap: 12px;
        margin-top: 12px;
      }

      &--relate {
        margin-top: 60px;

        h2 {
          @include text-style(700, 34px, 44px, left, #ee9416);
          text-transform: uppercase;

          margin-bottom: 0px;
        }

        .slider {
          width: 100%;
          margin-top: 48px;
          .item {
            padding: 0 15px;

            @include md {
              padding: 0;
            }

            .image {
              position: relative;
              height: 258px;

              margin-bottom: 10px;

              img {
                border-radius: 8px;
              }
            }

            p {
              span {
                @include text-style(400, 14px, 16px, left, $white);
              }

              :first-child {
                text-transform: uppercase;
                position: relative;
              }
            }

            h3 {
              @include text-style(700, 24px, 34px, left, $white);
              margin-bottom: 0;

              cursor: pointer;

              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;

              &:hover {
                color: #ee9416;
                transition: 0.5s;
              }
            }

            a {
              @include text-style(400, 14px, 16px, left, #ee9416);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
