.help {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0f0830;

  &__header {
    width: 100%;

    padding-top: $headerHeight;

    &__content {
      width: 100%;

      background-image: url("/images/background-news-page.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 70px 0;

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }

  &__content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 10;
    overflow: hidden;
    padding: $headerHeight;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include lg {
      flex-direction: column;
      justify-content: flex-start;
      gap: 24px;
    }

    @include sm {
      padding: $headerHeight 14px;
    }

    &__item {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      padding: 24px;

      @include sm {
        padding: 12px;
      }
    }

    &__side {
      display: flex;
      flex-basis: 30%;
      max-height: 100%;
      height: auto;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;

      .ant-collapse {
        width: 100%;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        @include text-style(700, 24px, 34px, left, $white);

        @include sm {
          font-size: 20px;
        }
      }

      .ant-collapse-ghost
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        p {
          @include text-style(400, 16px, 26px, left, $white);
          padding: 4px 0px 4px 12px;

          @include sm {
            font-size: 14px;
          }

          &:hover {
            text-decoration: underline;
            text-underline-offset: 4px;
            cursor: pointer;
          }
        }
      }
    }

    &__desc {
      display: flex;
      flex-basis: 67%;
      height: 100%;
      width: 100%;
      flex-direction: column;
      gap: 14px;

      &__header {
        border-bottom: 2px solid $white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          @include text-style(700, 28px, 38px, left, $white);

          @include sm {
            font-size: 20px;
          }
        }
      }

      &__content {
        max-height: calc(100vh - $headerHeight);
        overflow: hidden;
        overflow-y: auto;
        @include text-style(400, 16px, 24px, left, $white);

        @include sm {
          font-size: 14px;
        }

        h2 {
          @include text-style(700, 24px, 34px, left, $white);

          @include sm {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.active-qs {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;

  &::after {
    position: absolute;
    content: " ";
    height: 100%;
    width: 4px;
    background-color: #ee9416;
    top: 0px;
    left: 0px;
    border-radius: 8px;
  }
}
