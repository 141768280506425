.filter-custom {
  width: 100%;

  &__wrapper {
    width: 100%;

    //CUSTOM CSS SELECT
    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      background: #191844 !important;
      border: 1px solid #474669 !important;
      border-radius: 4px !important;

      height: 34px !important;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-select-arrow {
      svg {
        fill: #ffffff;
      }
    }

    .ant-select-selection-placeholder {
      @include text-style(400, 14px, 22px, left, #75748f);
    }

    .ant-select-selection-item {
      @include text-style(400, 14px, 22px, left, $white);
    }
  }
}
