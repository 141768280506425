.footer {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 30px 250px;
  gap: 10px;
  background: #231d49;

  display: flex;
  justify-content: space-between;

  @include xxxl {
    padding: 30px 100px;
  }

  @include xl {
    padding: 30px 48px;
  }

  @include lg {
    padding: 30px 16px;
  }

  @include md {
    flex-direction: column;
  }

  &__logo {
    padding-bottom: 24px;
    max-width: 300px;

    .image {
      width: 200px;
      cursor: pointer;
      margin-bottom: 14px;
    }

    .social {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 21px;
    }

    p {
      @include text-style(400, 14px, 17px, none, $white);

      @include lg {
        font-size: 12px;
      }
      width: 240px;
    }

    @include lg {
      max-width: 200px;
    }

    @include md {
      max-width: 300px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__col {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 64px;

      @include lg {
        gap: 14px;
      }

      @include sm {
        gap: unset;
        justify-content: space-between;
      }

      &--item {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--no-hover {
          &:hover {
            text-decoration: none !important;
            cursor: auto !important;
          }
        }
      }
    }

    h4 {
      @include text-style(700, 16px, 26px, left, $white);

      @include lg {
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 4px;
      }
    }

    ul {
      padding-inline-start: 0;
      margin-bottom: 0px;

      li {
        list-style: none;
        text-align: left;

        a {
          @include text-style(400, 16px, 26px, none, $white);

          &:hover {
            color: #ee9416;
            transition: 0.5s;
          }

          @include lg {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;

    @include sm {
      justify-content: flex-start;
    }

    label {
      @include text-style(700, 16px, 26px, none, $white);
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      input {
        padding: 16px 24px;
        width: 336px;
        height: 52px;
        left: 0px;
        top: 0px;

        background: #f1effa;
        border-radius: 226px;
        outline: none;

        font-size: 12px;

        @include xl {
          width: 250px;
        }

        @include md {
          width: 336px;
        }

        @include xs {
          width: 250px;
        }
      }

      button {
        position: absolute;
        left: 234.5px;

        width: 97px;
        height: 44px;
        background: #ee9416;
        border-radius: 80px;

        @include text-style(600, 12px, 24px, center, $white);

        @include xl {
          left: 150px;
        }

        @include md {
          left: 234.5px;
        }

        @include xs {
          left: 150px;
        }
      }
    }

    .ant-form-item-explain-error {
      margin-top: 8px;
      color: #ee9416;
    }

    .ant-btn.ant-btn-loading {
      position: absolute;
    }
  }
}
