.member {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 100px;

  &__background {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: -80%;

    background-image: url("/images/background-card-2.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @include xxxl {
      top: -60%;
    }

    @include xxl {
      top: -40%;
    }

    @include xl {
      top: -25%;
    }

    @include md {
      top: -15%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    padding-left: 250px;

    @include xxxl {
      padding-left: 80px;
    }

    @include xxl {
      flex-direction: column;
      padding-left: 0;
    }

    &__left {
      width: 30%;

      @include xxl {
        width: 100%;
        padding: 0 48px;
      }

      h2 {
        @include text-style(600, 80px, 110%, left, #ee9416);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: BarlowCondensed;

        max-width: 570px;
        margin-bottom: 0px;

        @include xxl {
          text-align: center;
          max-width: 100%;
        }

        @include md {
          font-size: 48px;
        }
      }

      div {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #faff0a 0%,
          rgba(250, 249, 2, 0) 100%
        );
        filter: blur(0.25px);
        transform: matrix(1, 0, 0.07, 1, 0, 0);
        height: 2px;
        width: 50%;

        margin-top: 20px;

        @include xxl {
          width: 100%;
        }

        @include md {
          width: 100%;
        }
      }

      p {
        @include text-style(700, 18px, 28px, left, $white);

        max-width: 450px;
        margin-top: 70px;

        @include xxl {
          text-align: center;
          max-width: 100%;
        }

        @include lg {
          margin-top: 20px;
        }

        @include md {
          font-size: 16px;
        }
      }
    }

    &__right {
      width: 70%;

      @include xxl {
        width: 100%;
      }

      &__card {
        background-image: url("/icons/card-member.svg");
        background-repeat: no-repeat;
        background-position: center;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-size: contain;
        height: 550px;

        padding: 0px 54px;
        padding-top: 50px;

        .image {
          width: 190px;
          height: 190px;
          border: 3px solid #dea309;
          position: relative;

          border-radius: 50%;

          img {
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .name {
          @include text-style(700, 20px, 27px, center, #ee9415);
          max-width: 200px;
          padding-top: 20px;
          padding-bottom: 10px;
        }

        .position {
          @include text-style(400, 14px, 24px, center, $white);
          max-width: 194px;
        }

        .quote {
          @include text-style(400, 14px, 24px, center, $white);
          max-width: 194px;
        }
      }

      .slick-prev,
      .slick-next {
        bottom: -20% !important;
        top: unset !important;

        width: 60px;
        height: 60px;

        background: #160e3d;
        border-radius: 16px;

        &::before {
          background-image: url("/icons/next-icon.svg");
          background-size: 10px 20px;
          display: inline-block;
          width: 10px;
          height: 20px;
          content: "";
        }
      }

      .slick-prev {
        left: 0;

        &::before {
          transform: rotate(180deg);
        }

        @include xxl {
          left: calc(50% - 70px);
          transform: translateY(50%);
        }
      }

      .slick-next {
        left: 80px;

        @include xxl {
          right: calc(50% - 70px);
          left: unset;
          // right: 50%;
          transform: translateY(50%);
        }
      }
    }
  }

  &__background2 {
    width: 100vw;
    height: 100%;
    position: absolute;

    background-image: url("/icons/bg-member.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
