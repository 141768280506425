.address {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    @include text-style(700, 20px, 30px, left, $white);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    gap: 8px;

    .ant-form-item {
      width: 100%;
    }

    .ant-select.ant-select-in-form-item {
      outline: none;
      @include text-style(700, 16px, 19px, left, #000000);

      &:hover {
        border-color: #ee9416;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px !important;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }

    &__field {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__combo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      @include xl {
        flex-direction: column;
        gap: 8px;
      }
    }

    &__label {
      min-width: 120px;
      @include text-style(400, 14px, 24px, left, $white);

      @include xs {
        min-width: 90px;
        max-width: 90px;
      }
    }

    &__input {
      height: 40px;
      outline: none;
      @include text-style(700, 16px, 19px, left, #000000);
      border-radius: 4px;
      &:hover {
        border-color: #ee9416;
      }

      .ant-input-suffix {
        cursor: pointer;
      }
    }

    &__btn-wrap {
      width: 100%;
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-start;
    }

    &__btn-item {
      width: unset !important;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      width: 122px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;
      margin-top: 16px;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }

    .ant-form-item-explain-error {
      color: #ee9416;
      padding-top: 5px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;

    &__item {
      padding: 8px;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      display: flex;

      @include xl {
        flex-direction: column;
        gap: 16px;
      }

      &:hover &__action {
        visibility: visible;

        @include xl {
          flex-direction: row;
        }
      }

      &__action {
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
        visibility: hidden;
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__btn {
        min-width: 100px;
        max-width: 100px;
        padding: 6px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        background-color: #339a03;

        @include text-style(700, 14px, 24px, center, $white);
        text-transform: uppercase;

        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      &__edit {
        background-color: #339a03;
      }

      &__delete {
        background-color: #ef4949;
      }

      &__field {
        display: flex;
        gap: 8px;

        &--title {
          @include text-style(700, 14px, 24px, left, $white);
          min-width: 120px;
        }

        &--content {
          @include text-style(400, 14px, 24px, left, $white);
        }
      }
    }
  }
}
