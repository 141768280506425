.form {
  width: 100%;
  padding-top: 18px;

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    &__label {
      @include text-style(400, 14px, 19px, left, $white);
    }

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: #ee7e16;

      &::after {
        background-color: #ee7e16;
      }
    }

    .ant-radio {
      &:hover {
        border-color: #ee7e16 !important;
      }
    }

    .ant-radio-wrapper {
      span {
        @include text-style(700, 14px, 20px, left, $white);
      }
    }

    .ant-form-item-explain-error {
      padding-top: 5px;
      color: #ee7e16;
    }

    .ant-select-selector {
      display: flex;
      align-items: center;

      height: 40px !important;
      border-color: #ee7e16 !important;
    }

    .ant-select-selection-item {
      @include text-style(700, 16px, 19px, left, #000000);
    }
  }

  &__action {
    display: flex;
    align-self: center;
    gap: 8px;

    .btn-update {
      display: flex;
      align-self: center;

      width: 122px;
      height: 39px;

      padding: 10px 24px;

      background: linear-gradient(90deg, #ee9416 0%, #ee7e16 100%);
      border-radius: 4px;
      border: none;
      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &:hover {
        background: white;
        color: #ee7e16;
        transition: 0.5s;
      }
    }

    .btn-cancel {
      display: flex;
      align-self: center;

      padding: 10px 24px;
      gap: 8px;

      width: 79px;
      height: 39px;

      background: #474669;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &:hover {
        background: white;
        color: #ee7e16;
        transition: 0.5s;
      }
    }
  }
}
