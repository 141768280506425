.box {
  h3 {
    @include text-style(700, 20px, 30px, left, $white);
  }

  &__tab {
    display: flex;
    align-items: center;

    @include md {
      flex-wrap: wrap;
    }
  }

  &__content {
    margin-top: 30px;

    @include lg {
      padding: 0 16px;
    }

    &__frame {
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .image {
          margin-bottom: 20px;
          position: relative;

          width: 100px;
          height: 100px;
        }

        p {
          @include text-style(900, 12px, 16px, center, $white);
          text-transform: uppercase;
        }

        .ant-btn {
          margin-top: 10px;
          border: 2px solid #ee9416;
          background: transparent;

          @include text-style(900, 10px, 14px, center, #ee9416);
          text-transform: uppercase;
          transition: 0.3s;

          &:hover {
            background: #ee9416;
            color: $white;
          }

          &[disabled] {
            pointer-events: none;
            background-color: #474669;
            border: none;
            color: $white;
          }
        }

        .col {
          align-items: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
