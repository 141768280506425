.portal-member {
  width: 100%;
  display: flex;
  gap: 24px;

  @include sm {
    flex-direction: column;
  }

  &__rate {
    flex-basis: 50%;
    width: 100%;
    height: 50%;
    border-right: 1px solid #22214e;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px 40px;

    &--tooltip {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    @include sm {
      border-right: unset;
      border-bottom: 1px solid #22214e;
      padding-bottom: 24px;
    }
  }

  &__form {
    width: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__btn {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }

    &__input {
      height: 40px;
      outline: none;
      @include text-style(700, 16px, 19px, left, #000000);
      border-radius: 4px;

      &:hover {
        border-color: #ee9416;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        @include text-style(400, 14px, 19px, left, $white);
      }
    }
  }

  &__info {
    &__btn {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }
  }

  &__next {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &__message {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }

    &__button-wrap {
      width: 100%;
      padding: 0px 50px;

      display: flex;
      align-items: center;
      gap: 50px;

      @include sm {
        padding: unset;
        gap: 20px;
      }
    }
  }

  .text {
    @include text-style(400, 14px, 19px, left, $white);
  }

  .text-bold {
    @include text-style(700, 14px, 19px, left, $white);
  }

  .text-center {
    text-align: center;
  }

  .text-success {
    @include text-style(700, 20px, 30px, center, #00d566);
  }
}
