.user {
  position: relative;
  width: 100vw;
  min-height: 100vh;

  padding: 140px 490px 50px 490px;
  background-image: url("/images/background-user.png");
  background-repeat: no-repeat;
  background-size: cover;

  &__layout {
    width: 100%;

    background: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 16px 24px 32px 24px;
  }

  @include xxxl {
    padding: 140px 150px 50px 150px;
  }

  @include xl {
    padding: 140px 100px 50px 100px;
  }

  @include lg {
    padding: 140px 32px 30px 32px;
  }

  @include md {
    padding: 90px 16px 30px 16px;
  }
}
