@import "../breakpoints";

.rule {
    width: 100%;
    height: calc(100vh - 84px); // Minus header height

    &__wrapper {
        padding: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
        .react-pdf__Document {
            position: relative;
            transform: scale(1.3);

            @include xxl {
                transform: scale(1);
            }

            @include xl {
                transform: scale(1.5);
            }

            @include lg {
                transform: scale(1.2);
            }

            @include md {
                transform: scale(0.8);
            }

            &:hover {
                .absolute-button {
                    opacity: 1;
                }
            }
        }

        .absolute-button {
            opacity: 0.05;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            background: white;
            color: black;
            -webkit-box-shadow: 10px 10px 20px 0px #333;
            box-shadow: 10px 10px 20px 0px #333;
            border-radius: 4px;
            transition: opacity ease-in-out 0.3s;

            span {
                margin: 0 12px;
            }

            button {
                width: 44px;
                height: 44px;
                background: white;
                border: 0;
                border-radius: 4px;
                font-size: 12px;

                &:hover {
                    cursor: pointer;
                    background: gray;
                }

                &:disabled {
                    background: gray;
                    cursor: not-allowed;
                }

                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}