@import "./variables";
@import "./breakpoints";
@import "./mixin";
/* Nunito Sans */
@font-face {
  font-family: NunitoSans;
  src: local("Nunito Sans"),
    url("../public/fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Barlow Condensed */
@font-face {
  font-family: BarlowCondensed;
  src: local("Barlow Condensed"),
    url("../public/fonts/BarlowCondensed/BarlowCondensed-ExtraBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: NunitoSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eb861d;
    // display: none;
  }

  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}
p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.ant-layout-content {
  background: #fff;
}

/* Form (all component...) */

.input-main {
  margin-bottom: 0;
  width: 100%;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }
  .ant-input {
    border-color: #e7e5e4;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $black;

    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: " ";
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: " ";
    .ant-col {
      &.ant-form-item-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $black;
      }
    }
  }
}
.select-main {
  margin-bottom: 0;
  width: 100%;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: " ";
  }
  .ant-select:not(.ant-select-disabled) .ant-select-selector {
    border-color: #e7e5e4 !important;
  }
  .ant-select-selector {
    height: 46px !important;
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    line-height: 46px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-select-selection-item {
    line-height: 46px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }
  input {
    height: 100% !important;
  }
}
.button-main {
  height: 54px;
  background: $primary;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: $black;

  padding: 0 90px;
  &:hover {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }
  &:focus {
    background: $primary;
    color: $black;

    border: none;
  }
}
.button-cancel {
  height: 54px;
  background: #fff;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: $black;

  padding: 0 90px;
  border: 1px solid #606060;
  &:hover {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }
  &:focus {
    background: $primary;
    color: $black;

    border: none;
  }
}
.ant-pagination-disabled {
  .ant-pagination-item {
    background: white !important;
  }
  .ant-pagination-prev.ant-pagination-disabled {
    button {
      background: white !important;
    }
  }
  .ant-pagination-next {
    button {
      background: white !important;
    }
  }
}
.pagination-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    min-width: 46px;
    height: 46px;
    border: 1px solid #e7e5e4;
    margin-right: 0;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 46px;
      color: $black;
      transition: 0.3s;

      &:hover {
        color: $primary;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid $primary;
    a {
      font-weight: 700;
    }
  }
  .ant-pagination-jump-prev {
    margin-right: 0;
    &:hover {
      .anticon {
        color: $black;
      }
    }
  }
  .ant-pagination-jump-next {
    margin-right: 0;
    &:hover {
      .anticon {
        color: $black;
      }
    }
  }
  .ant-pagination-prev {
    min-width: 46px;
    height: 46px;

    &:hover {
      .ant-pagination-item-link {
        border: 1px solid #e7e5e4;
        color: $primary;
      }
    }
  }
  .ant-pagination-next {
    min-width: 46px;
    height: 46px;

    &:hover {
      .ant-pagination-item-link {
        border: 1px solid #e7e5e4;
        color: $primary;
      }
    }
    margin-left: 8px;
  }
  .ant-select {
    &:hover {
      .ant-select-selector {
        border-color: #e7e5e4;
      }
    }
  }
  .ant-select-selector {
    height: 46px !important;
  }
  .ant-select-selection-item {
    line-height: 46px !important;
    padding-right: 0 !important;
  }
  .ant-pagination-options {
    margin-left: 8px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #e7e5e4;
    box-shadow: none;
  }
  .ant-select-arrow {
    display: none;
  }
}
.radio-main {
  :global(.ant-radio-inner) {
    border-color: $black;

    width: 20px;
    height: 20px;
  }
  :global(.ant-radio-inner:after) {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  }
  :global(.ant-radio-checked .ant-radio-inner) {
    border: 1px solid $primary;
  }
  :global(.ant-radio-checked::after) {
    border: 1px solid $primary;
  }
  :global(.ant-radio-checked .ant-radio-inner::after) {
    background: $primary;
  }
  :global(.ant-radio-input:focus + .ant-radio-inner) {
    box-shadow: 0 0 2px 3px #ffc72c2a;
  }
  .ant-radio {
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-innerant-radio-inner {
      border-color: $primary;
      &::after {
        background-color: $primary;
      }
    }
  }
}
.btn-action {
  height: 24px;
  background: #e7e5e4;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: $black;

  padding: 0 16px;
  span {
    padding: 4px 0;
  }
  &:hover {
    background: #979390;
    color: $black;

    border: none;
  }
  &:focus {
    background: #979390;
    color: $black;

    border: none;
  }
  &:disabled {
    background: rgba(254, 168, 0, 0.4);
  }
}

.hidden {
  display: none;
}
.input-password-main {
  margin-bottom: 0px;
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: " ";
    .ant-col {
      &.ant-form-item-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $black;
      }
    }
  }
}

.ant-modal-content {
  border-radius: 4px;
  overflow: hidden;
}
.ant-modal-body {
  padding: 40px 48px;

  @include sm {
    padding: 16px 22px;
  }
}
.ant-modal-footer {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  .ant-btn {
    height: 54px;
    width: 334px;
    &.ant-btn-primary {
      color: $black;

      background-color: $primary;
      border: 1px solid #606060;
      &:hover {
        background-color: $primary;
      }
    }
    &.ant-btn-default {
      color: $black;

      background-color: $primary;
      border: 1px solid #606060;
      &:hover {
        background-color: $primary;
      }
    }
  }
}
.group-radio {
  width: 100%;
}

.ant-tabs-tab-btn {
  transition: 0.15s !important;
}

.tab-main {
  .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-nav {
    margin: 0;
    background-color: $white;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    flex: 0.5;
    justify-content: center;
    padding: 16px 0;

    &:hover {
      color: $primary;
    }
  }
  .ant-tabs-tab-active {
    flex: 0.5;
    justify-content: center;
    padding: 16px 0;

    &:hover {
      color: black !important;
    }

    .ant-tabs-tab-btn {
      &:hover {
        color: black !important;
      }
    }
  }
  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $black;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black;
    font-weight: 700;
  }
  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: $primary;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-ink-bar {
    height: 1px;
    background-color: $primary;
  }
  .ant-tabs-nav-operations {
    display: none;
  }
}
.text-warring-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: $black;
}

.image-main {
  transition: 0.5s;
  &:hover {
    transform: scale(1.2);
  }
}

.container {
  max-width: $w-content;
  margin: 0 auto;
  padding: 32px 0 71px 0;
}
.radio-main {
  .ant-radio-inner {
    border-color: $black;

    width: 20px;
    height: 20px;
  }
  .ant-radio-inner:after {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 1px solid $primary;
  }
  .ant-radio-checked::after {
    border: 1px solid $primary;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background: $primary;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 2px 3px #ffc72c2a;
  }
}

// Tab main
.tab-main-wrapper {
  .ant-tabs-nav::before {
    border-bottom: 1px solid #e7e5e4;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 8px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $black;

    background: #f8f8f8;
  }
  .ant-tabs-tab-active {
    background: $primary;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #e7e5e4;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black;
  }
  .ant-tabs-ink-bar {
    background: #fff;
  }
}
// date picker
.datepicker-main {
  background: $white;
  border: 1px solid #e7e5e4;
  height: 32px;
}

// table
.ant-table {
  background: transparent;
  color: $white;
}

.ant-table-tbody > tr > td {
  border: 1px solid $border-table;
  text-align: center;
  padding: 10px;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.rate-main .ant-rate-star-zero svg path {
  stroke: $primary;
  stroke-width: 50px;
  fill: white;
}

.heading-main {
  margin: 2rem 0 1.5rem 0;
  font-weight: 600;
  font-size: 1.4rem;
}

.ant-spin-dot-item {
  background-color: #ffc72c;
}

.ant-dropdown {
  position: fixed;
}

.ant-tabs-tab {
  flex: 1 !important;
}

.react-simple-image-viewer__modal {
  z-index: 1000000 !important;
}

.facebook-color {
  &:hover {
    filter: invert(47%) sepia(42%) saturate(6363%) hue-rotate(186deg)
      brightness(106%) contrast(92%);
  }
}
.twitter-color {
  &:hover {
    filter: invert(50%) sepia(96%) saturate(1968%) hue-rotate(175deg)
      brightness(98%) contrast(93%);
  }
}
.youtube-color {
  &:hover {
    filter: invert(31%) sepia(87%) saturate(7495%) hue-rotate(359deg)
      brightness(104%) contrast(132%);
  }
}

.link-color {
  &:hover {
    filter: invert(31%) sepia(87%) saturate(7495%) hue-rotate(359deg)
      brightness(0%) contrast(132%);
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: $primary;
  border-color: $primary;
}

.ant-checkbox:hover,
.ant-checkbox-inner,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: unset;
  border-color: #d72323;

  &::after {
    border: 2px solid #d72323;
    border-top: 0;
    border-left: 0;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-form-item {
  margin: unset;
}

.ant-drawer-mask {
  top: $headerHeight; // HEADER

  @include sm {
    top: $headerHeightMobile;
  }
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  top: $headerHeight;

  @include sm {
    top: $headerHeightMobile;
  }
}

.ant-drawer-header {
  display: none;
}

.ant-drawer-body {
  padding: 48px 24px;
  background: #110a29;
}

.ant-dropdown-arrow::before {
  background-color: rgba(15, 8, 48, 0.9);
}

.ant-table-body {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eb861d;
  }
}

.ant-pagination-options {
  display: none;
}

.loading-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us {
  width: 100%;
  background: #0f0830;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.react-confirm-alert-body {
  border-radius: 16px;
  min-width: 500px;
  padding: 32px 64px;
  text-align: center;
  @include text-style(400, 16px, 26px, center, #191844);

  h1 {
    @include text-style(700, 24px, 34px, center, #191844);
    margin-bottom: 8px;
  }

  @include sm {
    transform: scale(0.7);
  }
}

.react-confirm-alert-button-group {
  margin-top: 24px;
}

.react-confirm-alert-button-group {
  justify-content: center;
  gap: 16px;

  button {
    border-radius: 4px;
    margin-right: unset;
    padding: 10px 24px;
    max-width: 170px;
    @include text-style(900, 14px, 19px, center, $white);

    &:first-child {
      background: #1dc14b;
    }

    &:last-child {
      background: #ee9416;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

// CUSTOM VIDEO MODAL

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.ant-empty-description {
  color: white;
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    .modal-video-movie-wrap {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;
      background-color: #333;
      animation-timing-function: ease-out;
      animation-duration: 0.3s;
      animation-name: modal-video-inner;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      -webkit-transition: -webkit-transform 0.3s ease-out;
      -moz-transition: -moz-transform 0.3s ease-out;
      -ms-transition: -ms-transform 0.3s ease-out;
      -o-transition: -o-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .modal-video-close-btn {
        display: none;
      }
    }
  }
}
