/**
    DEFINE ALL BREAKPOINT SCREEN
**/

$screen-xxxl-max: 1600.01px;
$screen-xxl-max: 1399.98px;
$screen-xl-max: 1199.98px;
$screen-lg-max: 991.98px;
$screen-ipad-mini: 830.8px;
$screen-md-max: 767.98px;
$screen-sm-max: 576.01px;
$screen-xs-max: 375px;

@mixin tablet-horizontal {
  @media (max-width: 1180.01px) and (min-width: 1023.99px) and (min-height: 767.99px) and (max-height: 820.01px) {
    @content;
  }
}

@mixin sm-height-over {
  @media (max-width: #{$screen-sm-max}) and (min-height: #{$screen-md-max}) {
    @content;
  }
}

@mixin sm-height-under {
  @media (max-width: #{$screen-sm-max}) and (max-height: #{$screen-md-max}) {
    @content;
  }
}

@mixin xxxl {
  @media only screen and (max-width: #{$screen-xxxl-max}) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin ipad-mini {
  @media only screen and (max-width: #{$screen-ipad-mini}) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width : #{$screen-sm-max}) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width : #{$screen-xs-max}) {
    @content;
  }
}
