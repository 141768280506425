.fourohfour {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;

    &--coming-soon {
      @include text-style(900, 160px, 100%, center, $white);
      text-transform: uppercase;
      text-shadow: -4px 3px 0 #ee9416, -14px 7px 0 #0a0e27;
      padding: 24px 48px;
      border: 5px solid $white;
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

      @include xl {
        font-size: 80px;
      }

      @include sm {
        font-size: 30px;
      }
    }
  }
}
