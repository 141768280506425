.step-1 {
  &__title {
    @include text-style(700, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__label {
    @include text-style(600, 14px, 16px, center, #191844);
    margin-bottom: 14px;
    max-width: 307px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
      margin-top: 8px;
    }
  }
}

.step-2 {
  &__title {
    @include text-style(700, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__label {
    @include text-style(600, 14px, 16px, center, #191844);
    margin-bottom: 14px;
    max-width: 307px;
  }

  &__otp {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 30px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
      margin-top: 8px;
    }
  }

  &__resend {
    @include text-style(600, 14px, 16px, center, #2c2c2c);
    text-decoration-line: underline;
  }

  .ant-statistic {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-statistic-content-value {
      @include text-style(700, 14px, 20px, center, #f05651);
    }
  }
}

.step-3 {
  &__title {
    @include text-style(700, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__label {
    @include text-style(600, 14px, 16px, center, #191844);
    margin-bottom: 14px;
    max-width: 307px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
      margin-top: 8px;
    }
  }
}

.step-4 {
  &__title {
    @include text-style(700, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__label {
    @include text-style(600, 14px, 16px, center, #191844);
    margin-bottom: 14px;
    max-width: 307px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
      margin-top: 8px;
    }
  }
}
