@import '../../breakpoints';

.login {
  &__title {
    @include text-style(600, 24px, 34px, center, #0b175d);
    font-family: BarlowCondensed;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;

    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--rememberLogin {
        display: flex;
        gap: 10px;
        align-items: center;

        p {
          @include text-style(400, 16px, 26px, center, $text-color);
        }

        .ant-checkbox-inner {
          border-radius: 50%;

          &::after {
            position: absolute;
            border: unset;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #ee9416;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &--forgetPassword {
        @include text-style(300, 16px, 26px, center, $text-color);
        cursor: pointer;
        color: #ee9416;
      }
    }

    &__button {
      margin-top: 8px;
    }

    &__alternative-login {
      margin: 18px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;

      &--title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #828282;
      }

      &--options {
        width: 100%;
        display: flex;
        gap: 24px;
        justify-content: center;

        img {
          cursor: pointer;
        }
      }
    }

    &__info {
      @include text-style(400, 16px, 26px, center, $text-color);

      span {
        @include text-style(700, 16px, 26px, center, $primary);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
