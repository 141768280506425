.intro-banner {
  width: 100%;
  position: relative;

  .slick-list {
    padding-bottom: unset;
  }

  .slick-slider {
    .slick-arrow {
      width: 20px;
      height: 20px;

      z-index: 10;

      top: 50%;
      transform: scale(3);
      opacity: 0.5;

      &:hover,
      &:active {
        opacity: 1;
      }

      @include sm {
        transform: scale(1);
      }
    }
  }

  .slick-prev {
    left: 5%;
  }

  .slick-next {
    right: 5%;
  }

  .slick-dots {
    bottom: 5%;
    text-align: center;

    .slick-active {
      width: 60px;

      button {
        width: 60px;
        border-radius: 16px;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;

    &__background {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 1) 100%
      );
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;

      z-index: 2;
      position: absolute;
    }

    &__img {
      position: relative;
      width: 100%;
      min-height: 100vh;

      img {
        object-fit: cover;
      }
    }

    &__title {
      width: 100%;
      height: 100%;

      padding: 0 84px;

      position: absolute;
      top: 20%;
      z-index: 7;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include xl {
        padding: 0px 48px;
      }

      @include md {
        padding: 0 16px;
      }

      @include md {
        top: 18%;
      }

      h2 {
        @include text-style(700, 60px, 133%, center, #ee9416);
        text-transform: uppercase;
        font-family: BarlowCondensed;
        letter-spacing: 1px;

        text-shadow: 0px 2px 4px rgba(5, 0, 35, 0.6);

        animation: fadeInDown 1s ease-in;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        @include lg {
          font-size: 48px;
        }

        @include sm {
          font-size: 35px;
        }
      }

      p {
        @include text-style(400, 20px, 26px, center, $white);
        max-width: 750px;

        animation: fadeInLeft 1s ease-in;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        @include md {
          max-width: 500px;
          font-size: 15px;
        }
      }

      .ant-btn {
        @include text-style(900, 20px, 26px, center, #ee9416);
        text-transform: uppercase;
        border: 1px solid #ee9416;
        background: transparent;

        padding: 12px 24px;
        width: 160px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-top: 36px;

        animation: fadeInRight 1s ease-in;

        transition: all 0.3s ease;

        &:hover {
          background-color: $white;
        }
      }
    }

    @include xxxl {
      background-size: cover;
    }
  }

  .white-text {
    color: $white;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
