.deposit-card {
  h3 {
    @include text-style(700, 20px, 30px, left, $white);
    margin-bottom: unset;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__tabs {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #191844;
      gap: 10px;
      margin-top: 24px;

      &--item {
        cursor: pointer;
        padding: 4px 28px;
        @include text-style(900, 14px, 26px, center, #75748f);
        transition: all 0.5s ease;
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);

        &--active {
          color: #ee9416;
          background: #191844;
          border-bottom: 1px solid #ee9416;
        }
      }
    }

    &__support {
      h3 {
        @include text-style(400, 16px, 26px, left, $white);
        margin-bottom: unset;
      }
    }
  }
}
