.contact {
  width: 100%;
  min-height: 100vh;
  background: #0f0830;

  &__header {
    width: 100%;
    height: 100%;

    padding-top: 100px;

    &__content {
      width: 100%;
      height: 100%;

      background-image: url("/images/background-news-page.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      h1 {
        @include text-style(600, 80px, 110%, center, $white);
        font-family: BarlowCondensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        margin-bottom: 0px;
        padding: 70px 0;

        @include lg {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }

  &__container {
    padding: 80px 250px 100px 250px;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__content {
      width: 100%;
      height: 100%;

      &--title {
        h4 {
          @include text-style(700, 34px, 44px, left, #ee9416);
          margin-bottom: 32px;
        }

        span {
          @include text-style(400, 18px, 26px, left, $white);
          max-width: 694px;
        }

        margin-bottom: 48px;
      }

      &--form {
        .title {
          @include text-style(700, 16px, 22px, left, $white);
        }

        .input {
          height: 57px;
          outline: none;
          @include text-style(700, 16px, 19px, left, #000000);
          border-radius: 8px;
          &:hover {
            border-color: #ee9416;
          }

          margin-top: 8px;
        }

        .text-area {
          height: 196px;
          outline: none;
          @include text-style(700, 16px, 19px, left, #000000);
          border-radius: 8px;
          &:hover {
            border-color: #ee9416;
          }

          margin-top: 8px;
        }

        .ant-form-item {
          margin-bottom: 30px;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 24px;
          gap: 8px;
          width: 125px;
          height: 62px;
          background: #ee9416;
          border-radius: 4px;
          border: none;

          @include text-style(900, 14px, 19px, center, $white);
          text-transform: uppercase;
        }

        .ant-form-item-explain-error {
          color: #ee9416;
          padding-top: 5px;
        }
      }

      &--location {
        width: 100%;
        height: 100%;

        iframe {
          border-radius: 8px;
        }

        @include lg {
          height: 600px;
        }

        @include md {
          height: 400px;
        }
      }
    }

    @include xxxl {
      padding: 84px 150px 100px 150px;
    }

    @include xxl {
      padding: 84px 100px 100px 100px;
    }

    @include xl {
      padding: 84px 70px 70px 50px;
    }

    @include md {
      padding: 50px 36px;
    }

    @include sm {
      padding: 50px 16px;
    }
  }
}
