.story-about-us {
  width: 100%;
  position: relative;
  height: 100%;
  padding-bottom: 100px;

  @include lg {
    padding-bottom: 150px;
  }

  @include md {
    padding-bottom: 100px;
  }

  &__content {
    position: relative;
    z-index: 7;
    display: flex;

    flex-direction: column;
    gap: 100px;

    padding: 0 350px;

    @include xxxl {
      padding: 0 48px;
      gap: 30px;
    }

    @include md {
      padding: 0 32px;
    }

    @include sm {
      padding: 0 16px;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h2 {
        @include text-style(600, 80px, 110%, left, #ee9416);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: BarlowCondensed;

        margin-bottom: 0px;

        @include xl {
          max-width: 100%;
          text-align: center;
        }

        @include md {
          font-size: 48px;
        }
      }

      div {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #faff0a 0%,
          rgba(250, 249, 2, 0) 100%
        );
        filter: blur(0.25px);
        transform: matrix(1, 0, 0.07, 1, 0, 0);
        height: 2px;
        width: 30%;

        margin-top: 20px;

        @include xl {
          width: 100%;
        }
      }
      p {
        @include text-style(700, 18px, 28px, left, $white);

        max-width: 500px;
        margin-top: 70px;

        @include xl {
          max-width: 100%;
          text-align: center;
        }

        @include lg {
          margin-top: 20px;
        }

        @include md {
          font-size: 16px;
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include xxxl {
        justify-content: center;
        gap: 50px;
      }

      @include md {
        flex-direction: column;
      }

      &--left {
        position: relative;
        width: 340px;
        height: 378px;

        @include sm {
          width: 240px;
          height: 270px;
        }
      }

      &--right {
        max-width: 750px;
        position: relative;

        .content {
          margin-bottom: 20px;
        }

        .seemore {
          @include text-style(700, 16px, 28px, left, #ee9416);
          text-transform: uppercase;

          border: 1px solid #ee9416;
          padding: 8px 12px;

          &:hover {
            color: #ffffff;
          }
        }

        p {
          @include text-style(400, 18px, 28px, left, #ffffff);
        }

        &::after {
          position: absolute;
          content: " ";
          left: -20px;
          height: 100%;
          width: 2px;
          top: 0;

          background: radial-gradient(
            50% 50% at 50% 50%,
            #ffffff 0%,
            rgba(250, 249, 2, 0) 100%
          );
          filter: blur(0.25px);

          @include md {
            display: none;
          }
        }

        @include xxl {
          max-width: 50%;
        }

        @include md {
          max-width: 100%;
        }
      }

      &:nth-child(2n - 1) {
        flex-direction: row-reverse;
        @include md {
          flex-direction: column;
        }
      }
    }
  }

  &__background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__background2 {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-image: url("/images/background-product.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
