.warning-modal {
  border-top: 6px solid $primary;
  border-radius: 8px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--warning {
      margin: 16px 0px 24px 0px;
      text-transform: capitalize;
      font-weight: bold;
    }

    &--error {
      margin-bottom: 16px;
    }

    &--text {
      text-transform: capitalize;
    }
  }
}
