.tab {
  display: flex;
  align-items: center;
  &:nth-child(n + 3) {
    margin-left: -1px;
  }

  &__item {
    cursor: pointer;
    transition: all 0.5s;
    padding: 4px 20px;
    border-bottom: 1px solid transparent;
    position: relative;
    transform: skew(-20deg);

    width: 100%;
    display: flex;
    justify-content: center;

    p {
      @include text-style(900, 14px, 26px, left, #75748f);
      text-transform: uppercase;
      padding: 4px 20px;
      transform: skew(20deg);
    }

    &:hover {
      border-bottom: 1px solid #ee9416;
    }

    &.active {
      border-bottom: 1px solid #ee9416;
      background-color: #191844;

      p {
        color: #ee9416;
      }
    }
  }

  .tab-background {
    background: #22214e;
  }
}
