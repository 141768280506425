@import "@styles/breakpoints";

.header {
  position: fixed;
  top: 0;
  display: flex;
  background-color: #140834;
  padding: 5px 24px;
  width: 100%;
  z-index: 15;

  @include sm {
    padding: 5px 16px;
  }

  &__left {
    width: 100%;
    display: flex;
    align-items: center;

    &__logo {
      margin-right: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }

    &__menu {
      display: flex;
      align-items: center;
      gap: 48px;

      @include xl {
        display: none;
      }

      &--item {
        @include text-style(700, 13px, 19px, center, $white);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--arrow {
          margin-left: 8px;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__right {
    width: fit-content;
    display: flex;

    cursor: pointer;

    @include xl {
      width: 100%;
      justify-content: flex-end;
    }

    .desktop {
      display: flex;

      @include xl {
        display: none;
      }
    }

    .mobile {
      display: none;

      @include xl {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
      }

      &__btn {
        @include text-style(700, 14px, 20px, center, $white);
        text-transform: uppercase;

        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          transition: background-color 0.3s;
          color: $primary;
        }
      }
    }

    &__noti {
      width: 61px;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
    }

    &__user {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      &--info {
        display: flex;
        gap: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &--name {
          display: flex;
          align-items: center;
          gap: 4px;
          @include text-style(900, 16px, 100%, center, $white);
          white-space: nowrap;
        }

        &--type {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-start;
          gap: 8px;

          &--item {
            display: flex;
            gap: 4px;
            align-items: center;

            p {
              @include text-style(900, 12px, 100%, center, $white);
            }
          }
        }
      }

      &--avatar {
        position: relative;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        display: flex;
        justify-content: center;

        width: 70px;
        height: 70px;

        img {
          border-radius: 50%;
          min-width: 60% !important;
          min-height: 60% !important;
        }

        .level {
          width: 62px;
          height: 30px;

          position: absolute;
          bottom: -5%;
          left: 50%;

          transform: translateX(-50%);
        }
      }
    }

    &__button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &--item {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        @include text-style(700, 14px, 0px, center, $white);
        cursor: pointer;
        transition: color 0.3s;
        white-space: nowrap;
        padding: 10px 24px;

        &:hover {
          color: $primary;
        }

        &--login {
          background-color: $primary;

          &:hover {
            background-color: transparent;
            transition: background-color 0.3s;
            color: $white;
          }
        }
      }
    }
  }

  &__drawer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
    overflow: hidden;
    overflow-y: auto;

    &--wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 48px;
      border-bottom: 1px solid #474669;

      &__user {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        &--info {
          display: flex;
          gap: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          &--name {
            display: flex;
            align-items: center;
            gap: 4px;
            @include text-style(900, 16px, 22px, center, $white);
            white-space: nowrap;
          }

          &--type {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-start;
            gap: 8px;

            &--item {
              display: flex;
              gap: 4px;
              align-items: center;

              p {
                @include text-style(900, 12px, 16px, center, $white);
              }
            }
          }
        }

        &--avatar {
          position: relative;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          display: flex;
          justify-content: center;

          width: 70px;
          height: 70px;

          img {
            border-radius: 50%;
            min-width: 60% !important;
            min-height: 60% !important;
          }

          .level {
            width: 62px;
            height: 30px;

            position: absolute;
            bottom: -5%;
            left: 50%;

            transform: translateX(-50%);
          }
        }
      }

      &__options {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &--item {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &__link {
            @include text-style(900, 10px, 12px, center, #ee9416);
            text-transform: uppercase;
            border-radius: 2px;
            border: 1px solid #ee9416;
            transition: all 0.3s;
            padding: 4px 10px;
          }

          p {
            @include text-style(700, 14px, 19px, left, $white);
            text-transform: capitalize;
          }

          .logout {
            color: #75748f;

            &:hover {
              color: $white;
            }
          }
        }
      }

      &__button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;

        &--item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          @include text-style(700, 14px, 0px, center, $white);
          cursor: pointer;
          transition: color 0.3s;
          white-space: nowrap;
          padding: 10px 24px;
          width: 100%;
          border: 1px solid $white;

          &:hover {
            color: $primary;
          }

          &--login {
            background-color: $primary;
            border: unset;

            &:hover {
              background-color: transparent;
              transition: background-color 0.3s;
              color: $white;
            }
          }
        }
      }
    }

    &--menu {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;
      width: 100%;

      .active-route {
        color: #eb861d !important;
      }

      .ant-collapse > .ant-collapse-item:last-child,
      .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        padding: unset;
      }

      .ant-collapse-header-text {
        @include text-style(900, 14px, 19px, left, $white);
        text-transform: uppercase;
      }

      .ant-collapse-content-box > a {
        @include text-style(900, 14px, 19px, center, $white);
        text-transform: uppercase;
      }

      .ant-collapse-ghost
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding-bottom: unset;
      }
    }
  }

  .active-menu {
    color: #eb861d;
  }
}

.ant-dropdown-menu {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(15, 8, 48, 0.9);

  .ant-dropdown-menu-item {
    border-bottom: 1px solid #251e48;
    background-color: transparent;
    padding: 10px 12.5px;
    min-width: 185px;
    cursor: pointer;

    &:hover {
      p {
        color: $primary;
      }
    }

    p {
      @include text-style(700, 14px, 19px, left, $white);
      text-transform: capitalize;
    }

    .logout {
      color: #75748f;

      &:hover {
        color: $white;
      }
    }

    &:last-child {
      border-bottom: unset;
    }
  }
}

.dropdown-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // &:hover {
  //   p {
  //     color: $primary;
  //   }
  // }

  &__link {
    @include text-style(900, 10px, 12px, center, #ee9416);
    text-transform: uppercase;
    border-radius: 2px;
    border: 1px solid #ee9416;
    transition: all 0.3s;
    padding: 4px 10px;
  }
}

.separator {
  height: 12px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.26);
}

.mission-modal {
  .mission-modal-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__tabs {
      display: flex;
      padding-left: 8px;

      @include sm {
        max-width: 100%;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }
  }

  .table-index {
    @include text-style(400, 16px, 26px, center, $white);
  }

  .table-title {
    border-right: 1px solid #474669;
    @include text-style(400, 16px, 26px, left, $white);
    text-transform: capitalize;
  }

  .table-progress {
    @include text-style(700, 14px, 26px, left, $white);
  }

  .table-reward {
    display: flex;
    gap: 8px;
    justify-content: center;

    &__item {
      display: flex;
      gap: 4px;
    }
  }

  .table-button {
    button {
      padding: 4px 16px;
      background: #1b8b5c;
      border-radius: 4px;
      @include text-style(900, 14px, 26px, center, $white);
      text-transform: uppercase;
      outline: unset;
      border: unset;
      cursor: pointer;

      &:hover {
        background-color: $white;
        color: #1b8b5c;
      }

      &[disabled] {
        background: #323254;
        color: #868698;
        cursor: unset;
      }
    }
  }

  .ant-table-thead {
    display: none;
  }

  .ant-modal-content {
    background-color: transparent;
  }

  .ant-table-tbody > tr > td {
    border: unset;
    padding: 20px 10px;
  }

  .ant-table-tbody > tr {
    &:nth-child(odd) {
      background: #191844;
    }

    &:nth-child(even) {
      background-color: #11102f;
    }
  }

  .ant-table-tbody {
    background: #22214e;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .ant-table-wrapper {
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;
  }
}

.welcome-modal {
  text-align: center;

  &__wrap-btn {
    width: 100%;
    padding: 0px 50px;

    display: flex;
    align-items: center;
    gap: 50px;

    @include sm {
      padding: unset;
      gap: 20px;
    }
  }
}
