@import "../../breakpoints";

.deposit {
    &__title {
        @include text-style(700, 24px, 34px, center, #0B175D);
        text-transform: uppercase;
        margin-bottom: 32px;
    }

    &__layout {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        &__button {
            margin-top: 8px;
        }
    }
}