.change-pass {
  h3 {
    @include text-style(700, 20px, 30px, left, $white);
  }

  &__form {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    &__label {
      @include text-style(400, 14px, 24px, left, $white);
    }

    &__input {
      height: 40px;
      outline: none;
      @include text-style(700, 16px, 19px, left, #000000);
      border-radius: 4px;
      &:hover {
        border-color: #ee9416;
      }

      .ant-input-suffix {
        cursor: pointer;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px;
      gap: 8px;
      width: 122px;
      height: 39px;
      background: #ee9416;
      border-radius: 4px;
      border: none;

      @include text-style(900, 14px, 19px, center, $white);
      text-transform: uppercase;

      &.ant-btn[disabled] {
        color: white;
        background: #474669;
      }
    }

    .ant-form-item-explain-error {
      color: #ee9416;
      padding-top: 5px;
    }
  }
}
