.number {
  padding-bottom: 24px;

  &__content {
    padding: 100px 250px;

    h3 {
      @include text-style(600, 80px, 110%, center, #ee9416);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: BarlowCondensed;
      position: relative;
      margin-bottom: 50px;

      @include md {
        font-size: 48px;
      }

      span {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #faff0a 0%,
          rgba(250, 249, 2, 0) 100%
        );
        filter: blur(0.25px);
        transform: matrix(1, 0, 0.07, 1, 0, 0);
        height: 3px;
        width: 25%;

        position: absolute;
        bottom: -5px;
        right: 50%;
        transform: translateX(50%);
      }
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 350px;
      max-width: 350px;
      border-radius: 16px;
      transition: 0.5s;

      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

      background: linear-gradient(#261958, #261958) padding-box,
        linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c)
          border-box;
      border: 8px solid transparent;

      span {
        @include text-style(700, 64px, 87px, center, #ee9415);

        @include xxl {
          font-size: 36px;
          line-height: 40px;
        }
      }

      p {
        @include text-style(700, 15px, 20px, center, #ffffff);
        text-transform: uppercase;

        @include xxl {
          font-size: 14px;
        }
      }

      &:hover {
        transform: translateY(-20px);
      }

      @include xxxl {
        max-width: 300px;
        height: 300px;
      }

      @include xxl {
        max-width: 250px;
        height: 250px;
      }

      @include xl {
        max-width: 350px;
        height: 350px;
      }

      @include md {
        max-width: 300px;
        height: 300px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-row {
      @include xl {
        padding: 0 48px;
      }

      @include sm {
        padding: 0 16px;
      }
    }

    @include xxxl {
      padding: 100px 48px;
    }

    @include lg {
      padding: 100px 16px;
    }

    @include sm {
      padding: 0;
    }
  }
}

@keyframes opacityChange {
  to {
    --opacity: 1;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --opacity {
  syntax: "<number>";
  initial-value: 0.5;
  inherits: false;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
