@import "@styles/breakpoints";

.banner {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__content {
    padding: 140px 200px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    @include xxxl {
      padding: 120px 150px 120px 150px;
    }

    @include xl {
      padding: 120px 48px 50px 48px;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
    }

    @include sm {
      gap: 12px;
      padding: 80px 16px 20px 16px;
    }

    @include tablet-horizontal {
      padding: 120px 120px 120px 48px;
      flex-direction: row;
      gap: 12px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      flex-basis: 45%;
      width: 100%;
      height: fit-content;

      @include xxl {
        gap: 8px;
      }

      @include xl {
        gap: 32px;
        flex-basis: unset;
        align-items: center;
      }

      @include sm {
        gap: 12px;
      }

      @include tablet-horizontal {
        align-items: flex-start;
      }

      &__title {
        display: flex;
        flex-direction: column;

        &--top {
          @include text-style(900, 52px, 110%, left, $white);
          font-family: BarlowCondensed;
          font-style: italic;
          text-transform: uppercase;
          padding-left: 5%;

          @include xxxl {
            font-size: 32px;
          }

          @include xxl {
            font-size: 28px;
            padding: unset;
          }

          @include xl {
            text-align: center;
          }

          @include sm {
            @include text-style(900, 18px, 110%, center, $white);
            font-style: italic;
            font-family: BarlowCondensed;
          }

          @include tablet-horizontal {
            text-align: left;
          }
        }

        &--bottom {
          display: flex;

          @include xxl {
            width: 60%;
          }

          @include xl {
            display: none;
          }

          span {
            max-width: 100%;
          }
        }

        &--bottom-mobile {
          display: none;

          @include xl {
            display: flex;
          }
        }
      }

      &__desc {
        @include text-style(400, 18px, 146%, left, $white);

        @include xxl {
          font-size: 14px;
        }

        @include sm {
          @include text-style(700, 12px, 146%, center, $white);
        }
      }

      &__button {
        @include text-style(900, 20px, 26px, left, $white);
        border-radius: 4px;
        border: unset;
        text-transform: uppercase;
        padding: 10px 24px;
        width: fit-content;
        background: linear-gradient(90deg, #ee9416 0%, #ee7e16 100%);
        display: flex;
        gap: 14px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: $white;
          color: $primary;
        }

        &:disabled {
          background: rgba(0, 0, 0, 0.2);
          cursor: not-allowed;

          &:hover {
            color: $white;
          }
        }

        @include sm {
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
    }

    &__right {
      display: flex;
      width: 100%;
      flex-basis: 53%;
      height: fit-content;
      position: relative;
      flex-direction: column;
      align-items: flex-start;

      @include xl {
        flex-basis: unset;
      }

      &__tab-wrapper {
        filter: drop-shadow(0px -4px 6px rgba(238, 148, 22, 0.32))
          drop-shadow(0px 0px 25px rgba(255, 189, 97, 0.18));
      }

      &__tab {
        width: fit-content;
        height: 100%;
        max-height: 43px;
        @include text-style(700, 18px, 110%, center, $white);
        text-transform: uppercase;
        background: linear-gradient(180deg, #1a1571 0%, #1f1c4f 100%);
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
        padding: 12px 32px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          90deg,
          rgba(238, 148, 22, 0) 0%,
          rgba(238, 148, 22, 1) 50%,
          rgba(238, 148, 22, 0) 100%
        );
      }

      &__table {
        background: rgba(28, 27, 73, 0.9);

        .ant-table-header {
          display: none;
        }

        .ant-table-body {
          overflow: hidden;
          box-shadow: 0px 0px 25px 0px #ffbd612e, 0px 0px 6px 0px #ee941652;

          @include xxxl {
            max-height: calc(100vh - 283px) !important;
          }

          @include xl {
            max-height: calc(50vh - 48px) !important;
          }

          @include sm {
            max-height: calc(50vh - 119px) !important;
          }
        }

        .ant-table-thead > tr > th {
          border: unset;
          @include text-style(700, 14px, 26px, center, $white);
        }

        .ant-table-tbody {
          tr {
            &:first-child {
              border: none;
              td:first-child {
                &::after {
                  display: none;
                }
              }
            }

            &:last-child {
              border: none;
            }

            &:nth-child(2) {
              td:first-child {
                &::after {
                  display: none;
                }
              }
            }

            td {
              position: relative;
              border: none;
              padding: 7px 0px;
              font-weight: 400;

              &:first-child {
                padding-left: 16px;
                &::after {
                  content: " ";
                  position: absolute;
                  width: calc(100% / 15% * 100%);
                  height: 1px;
                  left: 0;
                  top: 0;
                  background: linear-gradient(
                    270deg,
                    rgba(77, 52, 80, 0) 3.52%,
                    #4d3450 52.2%,
                    rgba(77, 52, 80, 0) 100%
                  );

                  @include sm {
                    width: calc(100% / 53% * 100%);
                  }
                }
              }

              &:last-child {
                padding-right: 8px;
              }
            }
          }
        }

        .ant-table-tbody > tr:last-child > td:first-child,
        .ant-table-tbody > tr:last-child > td:last-child {
          border-radius: unset;
        }

        .ant-table-tbody {
          tr:nth-child(even) {
            background: linear-gradient(
              90deg,
              #1f1c4f 0.48%,
              #1f1a6d 49.2%,
              #1f1c4f 100%
            );
          }

          tr:nth-child(odd) {
            background: linear-gradient(
              90deg,
              #27245e 0.48%,
              #1a1562 49.2%,
              #27245e 100%
            );
          }
        }
      }
    }
  }

  &__nav-mobile {
    display: none;

    @include xl {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 8px;

      &--item {
        position: relative;
        cursor: pointer;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.3;
      }

      &--item-active {
        opacity: 1;
      }
    }

    @include tablet-horizontal {
      display: none;
    }
  }

  &__nav {
    height: fit-content;
    max-width: 126px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 10;
    gap: 10px;
    margin-right: 10px;

    &--item {
      position: relative;
      cursor: pointer;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
    }

    &--item-active {
      opacity: 1;
    }

    @include xl {
      display: none;
    }

    @include tablet-horizontal {
      display: flex;
    }
  }
}

.action-button {
  background: #eb861d;
  padding: 4px 16px;
  border-radius: 4px;
  border: unset;
  @include text-style(700, 12px, 18px, center, $white);
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: $white;
    color: #eb861d;
  }

  @include sm {
    padding: 8px 5.5px;
    @include text-style(900, 10px, 16px, center, $white);
    white-space: nowrap;
  }
}

.disabled {
  background: #a92434;
  cursor: not-allowed;
}

.status {
  @include text-style(500, 14px, 26px, center, $white);

  &__inplay {
    text-align: center;
    color: #23dd4c;
  }

  &__soonplay {
    text-align: center;
    color: #eb861d;
  }
}

.roomCode {
  @include text-style(700, 16px, 26px, left, $white);
}

.description {
  @include text-style(400, 16px, 26px, left, $white);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.players {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  &__count {
    margin-top: 2px;
  }

  @include sm {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.room-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .room-code {
    @include text-style(700, 14px, 22px, center, $white);
  }

  .room-desc {
    @include text-style(400, 10px, 14px, left, $white);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
